import { invokeApig } from "../libs/awsLib";

const apiName = "round";

// Round API
export const rounds = () => invokeApig({ endpoint: apiName, path: "/round" });
export const roundsByCompetitionId = competitionId =>
  competitionId && competitionId !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: "/round",
        queryParams: { competitionId: competitionId }
      })
    : [];
export const roundsLite = () =>
  invokeApig({
    endpoint: apiName,
    path: "/round",
    queryParams: { partial: true }
  });
export const roundsLiteByYear = roundYear =>
  invokeApig({
    endpoint: apiName,
    path: "/round",
    queryParams: { partial: true, roundYear: roundYear }
  });
export const roundsByYear = roundYear =>
  invokeApig({
    endpoint: apiName,
    path: "/round",
    queryParams: { roundYear: roundYear }
  });
export const roundsLiteByCompetitionId = competitionId =>
  competitionId && competitionId !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: "/round",
        queryParams: { partial: true, competitionId: competitionId }
      })
    : [];
export const getRound = id =>
  id !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: `/round/${id}`
      })
    : {};
export const saveRound = (id, round) =>
  invokeApig({
    endpoint: apiName,
    path: `/round/${id}`,
    method: "PUT",
    body: round
  });
export const saveRoundScore = (id, score) =>
  invokeApig({
    endpoint: apiName,
    path: `/round-score/${id}`,
    method: "PUT",
    body: score
  });
export const createRound = round =>
  invokeApig({
    endpoint: apiName,
    path: "/round",
    method: "POST",
    body: round
  });
export const deleteRound = id =>
  invokeApig({
    endpoint: apiName,
    path: `/round/${id}`,
    method: "DELETE"
  });
