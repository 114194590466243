import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getHandicapCat, getHandicapBuffer } from "libs/handicapUtils";
import "./GolferListDetail.css";

const displayName = golfer => {
  return golfer.givenName + " " + golfer.familyName;
};

const renderList = props => {
  return [{}].concat(props.golfers).map((item, i) =>
    i !== 0 ? (
      <tr key={i}>
        <td className="name-col">
          <Link to={props.drillDownLink.replace(":golferId", item.id)}>
            {displayName(item)}
          </Link>
        </td>
        {/* <td className="status-col hidden-sm hidden-xs">
          {item.memberStatus ? "Member" : "Guest"}
        </td> */}
        <td className="handicap-col">{item.handicap}</td>
      </tr>
    ) : null
  );
};

export default props => (
  <Table
    className="GolferListDetail handicaps-table"
    striped
    bordered
    condensed
  >
    <thead>
      <tr>
        <th className="name-col">Name</th>
        {/* <th className="status-col hidden-sm hidden-xs">Status</th> */}
        <th className="handicap-col">
          <span>Handicap</span>
        </th>
      </tr>
    </thead>
    <tbody>{renderList(props)}</tbody>
  </Table>
);
