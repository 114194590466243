import React, { Component } from "react";
import * as API from "API";
import autumnImg from "img/calendar/autumn.png";
import beerImg from "img/calendar/beer.png";
import captainImg from "img/calendar/captain.png";
import frenchFlagImg from "img/calendar/frenchflag.png";
import nolfolkImg from "img/calendar/nolfolk.jpg";
import summerImg from "img/calendar/summer.png";
import PageHeader from "components/page-header/PageHeader";
import { Timeline, TimelineItem } from "components/timeline/Timeline";
import { CALENDAR_CONTENT as PAGE_CONTENT } from "libs/content";

export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendar: []
    };
  }
  componentDidMount = async () => {
    try {
      this.props.setLoading(true);

      const calendar = await API.content("calendar");

      this.props.setLoading(false);

      const state = {
        calendar: calendar
      };
      this.setState(state);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };
  render = () => (
    <section id="calendar">
      <div>
        <PageHeader
          heading={PAGE_CONTENT.HEADING}
          subHeading={PAGE_CONTENT.SUB_HEADING}
          centered={true}
          icon={PAGE_CONTENT.HEADING_ICON}
        />
        <div className="row">
          <div className="col-lg-12">
            <Timeline>
              {[{}]
                .concat(this.state.calendar)
                .map((item, idx) =>
                  idx !== 0 && item ? (
                    <TimelineItem
                      key={idx}
                      content={item}
                      className={
                        (idx % 2 > 0 ? "timeline-inverted " : "") +
                        item.CLASSNAME
                      }
                      img={
                        item.FRANCE
                          ? frenchFlagImg
                          : item.SUMMER
                          ? summerImg
                          : item.CAPTAIN
                          ? captainImg
                          : item.AUTUMN
                          ? autumnImg
                          : item.BEER
                          ? beerImg
                          : item.NOLFOLK
                          ? nolfolkImg
                          : null
                      }
                    />
                  ) : null
                )}
            </Timeline>
          </div>
        </div>
      </div>
    </section>
  );
}
