import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ReigningChampions.css";

const getFilteredComps = comps => {
  const latestComps = [
    comps.find(itm => itm.compType === "El Bandito Trophy"),
    comps.find(itm => itm.compType === "Bat & Ball Cup"),
    comps.find(itm => itm.compType === "Masters"),
    comps.find(itm => itm.compType === "U.S. Open"),
    comps.find(itm => itm.compType === "The Open"),
    comps.find(itm => itm.compType === "PGA"),
    comps.find(itm => itm.compType === "Players"),
    comps.find(itm => itm.compType === "Captains Day")
  ];
  return latestComps;
};

export default props => (
  <div className="ReigningChampions">
    <h4>Reigning Champions</h4>
    <Table striped bordered condensed>
      <thead>
        <tr>
          <th className="comp-col">Competition</th>
          <th className="name-col">Winner</th>
        </tr>
      </thead>
      <tbody>
        {[{}].concat(getFilteredComps(props.comps)).map((item, idx) =>
          idx !== 0 && item && item.winner && item.winner.id ? (
            <tr key={idx}>
              <td>
                <Link to={"/competition/summary/" + item.id}>
                  {item.compType}
                </Link>
              </td>
              <td>{item.winner.givenName + " " + item.winner.familyName}</td>
            </tr>
          ) : null
        )}
      </tbody>
    </Table>
  </div>
);
