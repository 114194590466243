import React from "react";
import LoaderButton from "components/loader-button/LoaderButton";
import LoaderLink from "components/loader-link/LoaderLink";

export default props => (
  <div className="FormSubmitButtons">
    <LoaderButton
      block
      bsStyle="primary"
      bsSize="large"
      disabled={props.disabled}
      type={props.onSubmit ? null : "submit"}
      onClick={props.onSubmit}
      isLoading={props.isLoading}
      text={props.overrideLabel ? props.overrideLabel : "Save " + props.label}
      loadingText="Saving…"
    />
    {props.entityId && props.entityId !== "new" && !props.self ? (
      <LoaderLink
        isLoading={props.isDeleting}
        onClick={props.onDeleteClick}
        text={"Delete " + props.label}
        loadingText="Deleting…"
      />
    ) : null}
  </div>
);
