import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { HelpBlock } from "react-bootstrap";
import { isInAdminGroup } from "libs/awsLib";
import LoaderButton from "components/loader-button/LoaderButton";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import PasswordValidation from "components/password-validation/PasswordValidation";
import { upperRegex, numberRegex, emailRegex } from "libs/formUtils";
import * as API from "API";
import "./ForgottenPassword.css";

export default class ForgottenPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      passwordFocused: false,
      passwordHasUpper: false,
      passwordHasNumber: false,
      passwordHasLength: false,
      newUser: null,
      forgottenPasswordError: false,
      forgottenPasswordConfirmationError: false,
    };
  }

  validateForm = () => {
    return this.state.email.length > 0 && this.state.email.match(emailRegex);
  };

  validateConfirmationForm = () => {
    return (
      this.state.confirmationCode.length > 0 &&
      this.state.passwordHasUpper &&
      this.state.passwordHasNumber &&
      this.state.passwordHasLength &&
      this.state.password === this.state.confirmPassword
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.updatePasswordValidation(event);
  };

  updatePasswordValidation = (event) => {
    if (event.target.id === "password") {
      this.setState({
        passwordHasUpper: event.target.value.match(upperRegex),
        passwordHasNumber: event.target.value.match(numberRegex),
        passwordHasLength: event.target.value.length >= 8,
      });
    }
  };

  focusPassword = (event) => {
    let errors = { ...this.state.errors };
    errors.password = null;
    this.setState({
      passwordFocused: true,
      errors: errors,
      forgottenPasswordConfirmationError: false,
    });
  };

  blurPassword = (event) => {
    let errors = { ...this.state.errors };
    errors.password =
      this.state.password.length > 0 &&
      (!this.state.passwordHasLength ||
        !this.state.passwordHasUpper ||
        !this.state.passwordHasNumber)
        ? "error"
        : null;
    errors.confirmPassword =
      this.state.confirmPassword !== "" &&
      this.state.password !== this.state.confirmPassword
        ? "error"
        : null;
    this.setState({
      passwordFocused: false,
      errors: errors,
    });
  };

  focusPasswordConfirmation = (event) => {
    let errors = { ...this.state.errors };
    errors.confirmPassword = null;
    this.setState({
      errors: errors,
      forgottenPasswordConfirmationError: false,
    });
  };

  blurPasswordConfirmation = (event) => {
    let errors = { ...this.state.errors };
    errors.confirmPassword =
      this.state.password !== this.state.confirmPassword ? "error" : null;
    this.setState({
      errors: errors,
    });
  };

  focusEmail = (event) => {
    let errors = { ...this.state.errors };
    errors.email = null;
    this.setState({
      errors: errors,
      forgottenPasswordError: false,
    });
  };

  blurEmail = (event) => {
    let errors = { ...this.state.errors };
    errors.email =
      this.state.email.length > 0 && !this.state.email.match(emailRegex)
        ? "error"
        : null;
    this.setState({
      errors: errors,
    });
  };

  handleConfirmationCodeFocus = (event) => {
    this.setState({
      forgottenPasswordConfirmationError: false,
    });
  };

  handleSubmit = async (event) => {
    this.setState({ isLoading: true });
    try {
      const newUser = await Auth.forgotPassword(this.state.email.toLowerCase());
      this.setState({
        newUser: newUser,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        forgottenPasswordError: e.message ? e.message : e,
      });
    }
  };

  handleConfirmationSubmit = async (event) => {
    this.setState({ isLoading: true });
    try {
      await Auth.forgotPasswordSubmit(
        this.state.email.toLowerCase(),
        this.state.confirmationCode,
        this.state.password
      );
      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);

      const isAdmin = await isInAdminGroup();
      this.props.setAdmin(isAdmin);

      const currentUser = await API.getGolferByEmail(
        this.state.email.toLowerCase()
      );
      this.props.setCurrentUser(
        currentUser && currentUser.length > 0 ? currentUser[0] : null
      );

      this.props.setAuthenticated(true);

      this.props.history.push("/");
    } catch (e) {
      this.setState({
        isLoading: false,
        forgottenPasswordConfirmationError: e.message ? e.message : e,
      });
    }
  };

  renderConfirmationForm = () => (
    <form>
      <FormAlert formError={this.state.forgottenPasswordConfirmationError} />
      <FormRow
        autoFocus
        controlId="confirmationCode"
        errors={this.state.errors}
        label="Confirmation Code"
        type="tel"
        value={this.state.confirmationCode}
        onChange={this.handleChange}
        onFocus={this.handleConfirmationCodeFocus}
        help={<HelpBlock>Please check your email for the code.</HelpBlock>}
      />
      <FormRow
        controlId="password"
        errors={this.state.errors}
        label="New Password"
        value={this.state.password}
        onChange={this.handleChange}
        onFocus={this.focusPassword}
        onBlur={this.blurPassword}
        autoComplete="new-password"
        type="password"
        validationMessage="Please enter a valid password."
        className={
          this.state.passwordFocused &&
          (!this.state.passwordHasLength ||
            !this.state.passwordHasNumber ||
            !this.state.passwordHasUpper)
            ? "password-incomplete"
            : ""
        }
        passwordValidation={
          <PasswordValidation
            passwordFocused={this.state.passwordFocused}
            passwordHasUpper={this.state.passwordHasUpper}
            passwordHasNumber={this.state.passwordHasNumber}
            passwordHasLength={this.state.passwordHasLength}
          />
        }
      />
      <FormRow
        controlId="confirmPassword"
        errors={this.state.errors}
        label="Confirm New Password"
        value={this.state.confirmPassword}
        onChange={this.handleChange}
        onFocus={this.focusPasswordConfirmation}
        onBlur={this.blurPasswordConfirmation}
        type="password"
        autoComplete="new-password"
        validationMessage="Passwords do not match."
      />
      <LoaderButton
        block
        bsSize="large"
        bsStyle="primary"
        disabled={!this.validateConfirmationForm()}
        onClick={this.handleConfirmationSubmit}
        isLoading={this.state.isLoading}
        text="Verify"
        loadingText="Verifying…"
      />
    </form>
  );

  renderForm = () => (
    <form>
      <FormAlert formError={this.state.forgottenPasswordError} />
      <FormRow
        autoFocus
        controlId="email"
        errors={this.state.errors}
        label="Email"
        value={this.state.email}
        onChange={this.handleChange}
        onFocus={this.focusEmail}
        onBlur={this.blurEmail}
        autoComplete="new-email"
        validationMessage="Please enter a valid email."
      />
      <LoaderButton
        block
        bsSize="large"
        bsStyle="primary"
        disabled={!this.validateForm()}
        onClick={this.handleSubmit.bind(this)}
        isLoading={this.state.isLoading}
        text="Reset"
        loadingText="Sending confirmation code…"
      />
    </form>
  );

  render = () => (
    <div className="ForgottenPassword">
      {this.state.newUser === null
        ? this.renderForm()
        : this.renderConfirmationForm()}
    </div>
  );
}
