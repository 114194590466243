import React, { Component } from "react";
import * as API from "API";
import GolferRoundsList from "components/golfer-rounds-list/GolferRoundsList";
import { Breadcrumb } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class GolferRounds extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    try {
      this.props.setLoading(true);

      const golferPromise = API.getGolfer(this.props.match.params.id);
      const golferRoundsPromise = API.golferRounds(this.props.match.params.id);

      const golferResp = await golferPromise;
      const golferRoundsResp = await golferRoundsPromise;

      this.props.setLoading(false);

      const golfer = {
        id: golferResp.id || "",
        givenName: golferResp.givenName || "",
        familyName: golferResp.familyName || "",
        handicap: golferResp.handicap || "",
        rounds: golferRoundsResp
      };
      this.setState(golfer);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  render = () => (
    <div className="GolferRounds">
      <Breadcrumb>
        <Breadcrumb.Item onClick={e => this.props.history.goBack()}>
          Back
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="golfer-breadcrumb">
          {this.state.givenName + " " + this.state.familyName}
        </Breadcrumb.Item>
      </Breadcrumb>

      <form>
        {this.state.rounds ? (
          <div>
            <GolferRoundsList
              rounds={this.state.rounds}
              golferId={this.state.id}
            />
          </div>
        ) : null}
      </form>
    </div>
  );
}

export default withRouter(GolferRounds);
