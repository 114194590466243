import React, { Component } from "react";
import * as API from "API";
import PageHeader from "components/page-header/PageHeader";
import EntityList from "components/entity-list/EntityList";
import YearSelection from "components/year-selection/YearSelection";
import { COMPETITIONS_CONTENT as PAGE_CONTENT } from "libs/content";
import "./Competitions.css";

export default class Competitions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      competitions: [],
      activeYear:
        localStorage.getItem("activeCompetitionYear") ||
        new Date().getFullYear(),
      resizeUpdater: this.updateDisplayNumber.bind(this),
    };
  }

  componentDidMount = async () => {
    this.updateDisplayNumber();
    window.addEventListener("resize", this.state.resizeUpdater);
    this.fetchCompetitions();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.state.resizeUpdater);
  };

  fetchCompetitions = async (year) => {
    try {
      this.props.setLoading(true);
      const results = await API.competitionsLiteByYear(
        year ? year : this.state.activeYear
      );
      this.props.setLoading(false);
      this.setState({ competitions: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  displayHeader = (competition) => {
    return competition.compName;
  };

  displayText = (competition) => {
    return (
      competition.dateFrom +
      (competition.dateTo ? " - " + competition.dateTo : "")
    );
  };

  handleYearSelect = (selectedKey) => {
    this.setState({ activeYear: selectedKey });
    try {
      localStorage.setItem("activeCompetitionYear", selectedKey);
    } catch (e) {
      console.log("Error setting local storage - activeCompetitionYear");
    }
    this.fetchCompetitions(selectedKey);
  };

  formattedURL = (id) => {
    return "/competition/summary/" + id;
  };

  updateDisplayNumber = () => {
    this.setState({
      displayNumber:
        window.innerWidth <= 285
          ? 1
          : window.innerWidth <= 350
          ? 2
          : window.innerWidth <= 425
          ? 3
          : 4,
    });
  };

  render = () => (
    <div className="Competitions">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <YearSelection
        activeKey={this.state.activeYear}
        onSelect={this.handleYearSelect}
        display={this.state.displayNumber}
      />
      <EntityList
        {...this.props}
        isLoading={this.state.isLoading}
        listItems={this.state.competitions}
        type="competition"
        header={this.displayHeader}
        text={this.displayText}
        hideAdd={this.props.isAdmin ? null : true}
        formattedURL={this.props.isAdmin ? null : this.formattedURL}
      />
    </div>
  );
}
