import { invokeApig } from "../libs/awsLib";

const apiName = "course";

// Course API
export const courses = () => invokeApig({ endpoint: apiName, path: "/course" });
export const coursesLite = () =>
  invokeApig({
    endpoint: apiName,
    path: "/course",
    queryParams: { partial: true }
  });
export const getCourse = id =>
  id !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: `/course/${id}`
      })
    : {};
export const saveCourse = (id, course) =>
  invokeApig({
    endpoint: apiName,
    path: `/course/${id}`,
    method: "PUT",
    body: course
  });
export const createCourse = course =>
  invokeApig({
    endpoint: apiName,
    path: "/course",
    method: "POST",
    body: course
  });
export const deleteCourse = id =>
  invokeApig({
    endpoint: apiName,
    path: `/course/${id}`,
    method: "DELETE"
  });
