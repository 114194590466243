import React, { Component } from "react";
import * as API from "API";
import { withRouter } from "react-router-dom";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import FormSubmitButtons from "components/form-submit-buttons/FormSubmitButtons";
import DropdownOptions from "components/dropdown-options/DropdownOptions";
import { FormGroup, ControlLabel, Radio, Checkbox } from "react-bootstrap";
import { lettersRegex, validateInput } from "libs/formUtils";
import "./NewsItem.css";

class NewsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsText: "",
      link: "",
      competitionId: "",
      roundId: "",
      forceAsOld: "",
      notify: "",
      linkNone: true,
      linkCompetition: false,
      linkRound: false,
      linkOther: false,
      loadingCompetitions: false,
      loadingRounds: false,
      competitions: [],
      rounds: [],
      errors: {}
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const results = await API.getNews(this.props.match.params.id);
      let competitions = [],
        rounds = [];
      if (results.competitionId) {
        competitions = await API.competitionsLite();
      }
      if (results.roundId) {
        rounds = await API.roundsLite();
      }
      this.props.setLoading(false);
      const news = {
        id: results.id || "",
        newsText: results.newsText || "",
        link: results.link || "",
        linkNone: !results.link && !results.competitionId && !results.roundId,
        linkCompetition:
          results.competitionId && results.competitionId.length > 0
            ? true
            : false,
        linkRound: results.roundId && results.roundId.length > 0 ? true : false,
        linkOther:
          results.link && !results.competitionId && !results.roundId
            ? true
            : false,
        competitionId: results.competitionId || "",
        competitions: competitions,
        roundId: results.roundId || "",
        rounds: rounds,
        forceAsOld: results.forceAsOld || "",
        notify: results.notify || ""
      };
      this.setState(news);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  validateForm = () => {
    return (
      this.state.newsText.length > 0 &&
      (!this.state.linkOther || this.state.link.length > 0) &&
      (!this.state.linkCompetition || this.state.competitionId.length > 0) &&
      (!this.state.linkRound || this.state.roundId.length > 0)
    );
  };

  handleChange = event => {
    this.setState({
      dirty: true,
      [event.target.id]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    });
  };

  handleFocus = event => {
    let errors = { ...this.state.errors };
    errors[event.target.id] = null;
    this.setState({
      errors: errors,
      formError: false
    });
  };

  handleBlur = event => {
    const regex = event.target.attributes.getNamedItem("data-regex");
    let errors = { ...this.state.errors };
    errors[event.target.id] = !validateInput(
      regex ? regex.value : null,
      this.state[event.target.id]
    )
      ? "error"
      : null;

    this.setState({
      errors: errors
    });
  };

  handleSubmit = async event => {
    let news = {
      newsText: this.state.newsText,
      link: this.state.competitionId
        ? "/competition/summary/" + this.state.competitionId
        : this.state.roundId
        ? "/round/summary/" + this.state.roundId
        : this.state.link,
      competitionId: this.state.competitionId,
      roundId: this.state.roundId,
      forceAsOld: this.state.forceAsOld,
      notify: this.state.notify
    };
    this.setState({ isLoading: true });
    try {
      if (this.props.match.params.id === "new") {
        await API.createNews(news);
      } else {
        await API.saveNews(this.state.id, news);
      }
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isLoading: false, formError: e.message });
    }
  };

  handleDelete = async event => {
    event.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete this news item?"
    );
    if (!confirmed) {
      return;
    }
    this.setState({ isDeleting: true });
    try {
      await API.deleteNews(this.state.id);
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isDeleting: false, formError: e.message });
    }
  };

  handleLinkTypeChange = async event => {
    const id = event.target.id;
    const value = event.target.value === "on";

    this.setState({
      linkNone: id === "linkNone" && value,
      linkCompetition: id === "linkCompetition" && value,
      linkRound: id === "linkRound" && value,
      linkOther: id === "linkOther" && value,
      link: "",
      competitionId: "",
      roundId: ""
    });

    if (id === "linkCompetition" && value) {
      await this.getCompetitions();
    }
    if (id === "linkRound" && value) {
      await this.getRounds();
    }
  };

  getCompetitions = async () => {
    if (this.state.competitions.length === 0) {
      this.setState({
        loadingCompetitions: true
      });
      const competitions = await API.competitionsLite();
      this.setState({
        competitions: competitions.slice(0, 5),
        loadingCompetitions: false
      });
    }
  };

  getRounds = async () => {
    if (this.state.rounds.length === 0) {
      this.setState({
        loadingRounds: true
      });
      const rounds = await API.roundsLite();
      this.setState({
        rounds: rounds.slice(0, 10),
        loadingRounds: false
      });
    }
  };

  render = () => (
    <div className="NewsItem">
      <form>
        <FormAlert formError={this.state.formError} />
        <h3>News Item</h3>
        <FormRow
          controlId="newsText"
          errors={this.state.errors}
          label="Text"
          value={this.state.newsText}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          regex={lettersRegex}
          validationMessage="Please enter some news text."
        />
        <FormGroup className="radio-form-group">
          <ControlLabel>Link</ControlLabel>
          <Radio
            id="linkNone"
            name="radioGroup"
            checked={this.state.linkNone}
            onChange={this.handleLinkTypeChange}
          >
            None
          </Radio>
          <Radio
            id="linkCompetition"
            name="radioGroup"
            checked={this.state.linkCompetition}
            onChange={this.handleLinkTypeChange}
          >
            Competition
          </Radio>
          <Radio
            id="linkRound"
            name="radioGroup"
            checked={this.state.linkRound}
            onChange={this.handleLinkTypeChange}
          >
            Round
          </Radio>
          <Radio
            id="linkOther"
            name="radioGroup"
            checked={this.state.linkOther}
            onChange={this.handleLinkTypeChange}
          >
            Other
          </Radio>
        </FormGroup>
        {this.state.loadingCompetitions ? (
          <h4 className="inline-loading">Loading Competitions...</h4>
        ) : this.state.competitions.length > 0 && this.state.linkCompetition ? (
          <FormRow
            errors={this.state.errors}
            componentClass="select"
            placeholder="select"
            controlId="competitionId"
            label="Competition"
            value={this.state.competitionId}
            onChange={this.handleChange}
            regex={lettersRegex}
            validationMessage="Please select a competition."
          >
            <DropdownOptions
              listItems={this.state.competitions}
              renderItemText={item => item.compName}
            />
          </FormRow>
        ) : null}
        {this.state.loadingRounds ? (
          <h4 className="inline-loading">Loading Rounds...</h4>
        ) : this.state.rounds.length > 0 && this.state.linkRound ? (
          <FormRow
            errors={this.state.errors}
            componentClass="select"
            placeholder="select"
            controlId="roundId"
            label="Round"
            value={this.state.roundId}
            onChange={this.handleChange}
            regex={lettersRegex}
            validationMessage="Please select a round."
          >
            <DropdownOptions
              listItems={this.state.rounds}
              renderItemText={item => item.courseName + " - " + item.roundDate}
            />
          </FormRow>
        ) : null}
        {this.state.linkOther ? (
          <FormRow
            controlId="link"
            errors={this.state.errors}
            label="Link"
            value={this.state.link}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            regex={lettersRegex}
            validationMessage="Please enter a link."
            help="e.g. '/calendar' or '/committee'"
          />
        ) : null}
        <hr />
        {!this.state.id ? (
          <Checkbox
            id="notify"
            checked={this.state.notify}
            onChange={this.handleChange}
          >
            Send email notification
          </Checkbox>
        ) : null}
        <Checkbox
          id="forceAsOld"
          checked={this.state.forceAsOld}
          onChange={this.handleChange}
        >
          Show without 'new' indicator
        </Checkbox>
        <FormSubmitButtons
          disabled={!this.validateForm()}
          isLoading={this.state.isLoading}
          label={"News Item"}
          entityId={this.state.id}
          isDeleting={this.state.isDeleting}
          onDeleteClick={this.handleDelete}
          onSubmit={this.handleSubmit.bind(this)}
        />
      </form>
    </div>
  );
}

export default withRouter(NewsItem);
