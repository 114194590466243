import React from "react";
import { Table } from "react-bootstrap";
import "./NewsItem.css";

export default props => (
  <div className={"NewsItem"}>
    <Table>
      <tbody>
        <tr>
          {props.new ? (
            <td className={"news-item-new"}>
              <span className="badge">
                <span className="fas fa-star" /> New
              </span>
            </td>
          ) : null}
          {props.new ? (
            <td>{props.children}</td>
          ) : (
            <td colSpan="2">{props.children}</td>
          )}
        </tr>
      </tbody>
    </Table>
    <hr className={"news-divider"} />
  </div>
);
