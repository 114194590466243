import React from "react";
import { ListGroupItem, ListGroup } from "react-bootstrap";
import "./GolferList.css";

const renderList = props => {
  return [{}].concat(props.listItems).map(
    (item, i) =>
      i !== 0 ? (
        <ListGroupItem
          key={i}
          onClick={e => props.edit(item)}
          header={item.name}
          className="GolferListItem"
        >
          {[{}]
            .concat(item.golfers)
            .map(
              (golfer, golferIdx) =>
                golferIdx !== 0
                  ? golfer.givenName +
                    " " +
                    golfer.familyName +
                    (golferIdx < item.golfers.length ? ", " : "")
                  : null
            )}
          {props.isGroupValid && !props.isGroupValid(item) ? (
            <span className="invalid text-danger">
              {props.validationMessage}
            </span>
          ) : null}
        </ListGroupItem>
      ) : (
        <ListGroupItem key="new" onClick={props.addClick}>
          <h4>
            <b>{"\uFF0B"}</b> Add{" "}
            <span className="cap-entity">{props.type}</span>
          </h4>
        </ListGroupItem>
      )
  );
};

export default props => (
  <ListGroup className="GolferList">{renderList(props)}</ListGroup>
);
