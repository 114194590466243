import React from "react";
import { Table } from "react-bootstrap";
import "./ScoresByRound.css";

export default props => (
  <div className="ScoresByRound">
    {[{}].concat(props.data).map(
      (item, idx) =>
        idx !== 0 && (!props.golferId || item.id === props.golferId) ? (
          <div key={idx}>
            <h4>
              #{" "}
              {item.position +
                " " +
                (item.tied ? "=" : "") +
                " - " +
                item.givenName +
                " " +
                item.familyName}
              {item.position === 1 ? (
                <span className="fas fa-trophy gold" />
              ) : item.position === 2 ? (
                <span className="fas fa-trophy silver" />
              ) : item.position === 3 ? (
                <span className="fas fa-trophy bronze" />
              ) : null}
            </h4>
            <Table striped bordered condensed>
              <thead>
                <tr>
                  <th className="round-col">Round</th>
                  <th className="score-col">Stableford</th>
                </tr>
              </thead>
              <tbody>
                {[{}]
                  .concat(props.rounds(item, props.field, props.lowRound))
                  .map(
                    (round, roundIdx) =>
                      roundIdx !== 0 ? (
                        <tr key={roundIdx}>
                          <td className={round.lowRound ? "low-round" : ""}>
                            {round.nbr}
                          </td>
                          <td className={round.lowRound ? "low-round" : ""}>
                            {round.score}
                          </td>
                        </tr>
                      ) : null
                  )}
                <tr>
                  <td className="total">Total</td>
                  <td className="total">{item[props.totalField]}</td>
                </tr>
              </tbody>
            </Table>
            <hr />
          </div>
        ) : null
    )}
  </div>
);
