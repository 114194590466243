import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { HelpBlock } from "react-bootstrap";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import LoaderButton from "components/loader-button/LoaderButton";
import { emailRegex } from "libs/formUtils";
import "./ConfirmAccount.css";

export default class ConfirmAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      confirmationCode: ""
    };
  }

  validateForm = () => {
    return (
      this.state.email.length > 0 &&
      this.state.email.match(emailRegex) &&
      this.state.confirmationCode.length > 0
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleFocus = event => {
    this.setState({
      errors: {},
      confirmAccountError: false
    });
  };

  blurEmail = event => {
    let errors = {
      email:
        this.state.email.length > 0 && !this.state.email.match(emailRegex)
          ? "error"
          : null
    };
    this.setState({
      errors: errors
    });
  };

  handleConfirmationSubmit = async event => {
    this.setState({ isLoading: true });
    try {
      await Auth.confirmSignUp(
        this.state.email.toLowerCase(),
        this.state.confirmationCode
      );
      this.props.history.push("/login");
    } catch (e) {
      this.setState({
        isLoading: false,
        confirmAccountError: e.message ? e.message : e
      });
    }
  };

  render = () => (
    <div className="ConfirmAccount">
      <form>
        <FormAlert formError={this.state.confirmAccountError} />
        <FormRow
          controlId="email"
          errors={this.state.errors}
          label="Email"
          value={this.state.email}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.blurEmail}
          autoComplete="new-email"
          validationMessage="Please enter a valid email."
        />
        <FormRow
          controlId="confirmationCode"
          type="tel"
          label="Confirmation Code"
          value={this.state.confirmationCode}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          help={<HelpBlock>Please check your email for the code.</HelpBlock>}
        />
        <LoaderButton
          block
          bsSize="large"
          bsStyle="primary"
          disabled={!this.validateForm()}
          onClick={this.handleConfirmationSubmit}
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    </div>
  );
}
