import React, { Component } from "react";
import * as API from "API";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Breadcrumb, FormGroup, ControlLabel, Col } from "react-bootstrap";
import HandicapChart from "components/handicap-chart/HandicapChart";
import AnnualGolferHandicapChange from "components/annual-golfer-handicap-change/AnnualGolferHandicapChange";
import {
  getHandicapCat,
  formatHandicapListForGolfer
} from "libs/handicapUtils";
import "./Handicap.css";

class Handicap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeYear: 2019,
      email: "",
      givenName: "",
      familyName: "",
      nickname: "",
      gender: "",
      handicap: "",
      memberStatus: false,
      roundHandicaps: [],
      resizeUpdater: this.updateChartWidth.bind(this)
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const resultsPromise = API.getGolfer(this.props.match.params.id);
      const roundHandicapsPromise = API.handicapsByYear(this.state.activeYear);
      const results = await resultsPromise;
      let roundHandicaps = await roundHandicapsPromise;
      const filteredList = formatHandicapListForGolfer(
        results.id,
        roundHandicaps
      );
      this.props.setLoading(false);

      const chartWidth =
        document.getElementsByClassName("right-section").length > 0
          ? document.getElementsByClassName("right-section")[0].offsetWidth
          : 0;

      const golfer = {
        id: results.id || "",
        email: results.email || "",
        givenName: results.givenName || "",
        familyName: results.familyName || "",
        nickname: results.nickname || "",
        gender: results.gender || "",
        handicap: results.handicap || "",
        memberStatus: results.memberStatus || false,
        roundHandicaps: filteredList ? filteredList.reverse() : filteredList,
        chartWidth: chartWidth
      };
      this.setState(golfer);

      window.addEventListener("resize", this.state.resizeUpdater);
    } catch (e) {
      this.props.setError(true);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.state.resizeUpdater);
  };

  updateChartWidth = () => {
    const chartWidth =
      document.getElementsByClassName("right-section").length > 0
        ? document.getElementsByClassName("right-section")[0].offsetWidth - 30
        : 0;
    this.setState({
      chartWidth: chartWidth
    });
  };

  render = () => (
    <div className="Handicap">
      <Breadcrumb>
        <Breadcrumb.Item onClick={e => this.props.history.goBack()}>
          Back
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="golfer-breadcrumb">
          {this.state.givenName + " " + this.state.familyName}
        </Breadcrumb.Item>
      </Breadcrumb>

      <form>
        <Col md={4} className="left-section">
          <FormGroup>
            <ControlLabel>Current Handicap (Category)</ControlLabel>
            <div>
              {this.state.handicap +
                " (" +
                getHandicapCat(this.state.handicap) +
                ")"}
            </div>
          </FormGroup>
          <FormGroup>
            <span className="fas fa-chart-bar" />
            <Link to={"/stats/" + this.state.id}>Show Stats</Link>
          </FormGroup>
          <FormGroup>
            <span className="fas fa-award" />{" "}
            <Link to={"/badges/" + this.state.id}>Show Badges</Link>
          </FormGroup>
        </Col>

        <Col md={8} className="right-section">
          {this.state.roundHandicaps.length > 1 ? (
            <HandicapChart
              noHeading={true}
              data={this.state.roundHandicaps}
              width={this.state.chartWidth}
              startYear={this.state.activeYear}
            />
          ) : null}
        </Col>

        {this.state.roundHandicaps.length > 0 ? (
          <AnnualGolferHandicapChange
            data={this.state.roundHandicaps}
            id={this.state.id}
          />
        ) : (
          <h4 className="no-details-to-show">
            There are no round handicap changes to display for the{" "}
            {this.state.activeYear} period.
          </h4>
        )}
      </form>
    </div>
  );
}

export default withRouter(Handicap);
