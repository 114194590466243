import React, { Component } from "react";
import * as API from "API";
import { getLoggedInEmail } from "libs/awsLib";
import { withRouter } from "react-router-dom";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import FormSubmitButtons from "components/form-submit-buttons/FormSubmitButtons";
import { FormGroup, Radio, ControlLabel, Alert } from "react-bootstrap";
import {
  emailRegex,
  lettersRegex,
  numbersOnlyRegex,
  validateInput
} from "libs/formUtils";

class Golfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      givenName: "",
      familyName: "",
      nickname: "",
      gender: "",
      handicap: "",
      memberStatus: false,
      notifications: false,
      errors: {}
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const results = await this.getGolfer();
      this.props.setLoading(false);
      const golfer = {
        id: results.id || "",
        email: results.email || "",
        givenName: results.givenName || "",
        familyName: results.familyName || "",
        nickname: results.nickname || "",
        gender: results.gender || "",
        handicap: results.handicap || "",
        memberStatus: results.memberStatus || false,
        notifications: results.notifications || false,
        noGolfer: results.noGolfer || null
      };
      this.setState(golfer);
    } catch (e) {
      this.props.setError(true);
    }
  };

  validateForm = () => {
    return (
      this.state.email.length > 0 &&
      this.state.email.match(emailRegex) &&
      this.state.givenName !== "" &&
      this.state.familyName !== "" &&
      this.state.handicap !== "" &&
      this.state.gender !== ""
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleFocus = event => {
    let errors = { ...this.state.errors };
    errors[event.target.id] = null;
    this.setState({
      errors: errors,
      formError: false
    });
  };

  handleBlur = event => {
    const regex = event.target.attributes.getNamedItem("data-regex");
    let errors = { ...this.state.errors };
    errors[event.target.id] = !validateInput(
      regex ? regex.value : null,
      this.state[event.target.id]
    )
      ? "error"
      : null;

    this.setState({
      errors: errors
    });
  };

  handleSubmit = async event => {
    let golfer = {
      email: this.state.email,
      givenName: this.state.givenName,
      familyName: this.state.familyName,
      nickname: this.state.nickname,
      gender: this.state.gender,
      handicap: this.state.handicap,
      memberStatus: this.state.memberStatus,
      notifications: this.state.notifications
    };
    this.setState({ isLoading: true });
    try {
      if (this.props.match.params.id === "new") {
        await API.createGolfer(golfer);
      } else {
        await API.saveGolfer(this.state.id, golfer);
      }
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isLoading: false, formError: e.message });
    }
  };

  handleDelete = async event => {
    event.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete this golfer?"
    );
    if (!confirmed) {
      return;
    }
    this.setState({ isDeleting: true });
    try {
      await API.deleteGolfer(this.state.id);
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isDeleting: false, formError: e.message });
    }
  };

  getGolfer = async () => {
    if (this.props.match.path === "/my-profile") {
      this.setState({ self: true });
      const email = await getLoggedInEmail();
      const response = await API.getGolferByEmail(email);
      return response && response.length > 0 ? response[0] : { noGolfer: true };
    } else {
      return API.getGolfer(this.props.match.params.id);
    }
  };

  handleMemberChange = event => {
    this.setState({
      memberStatus:
        (event.target.id === "memberStatusYes" &&
          event.target.value === "on") ||
        (event.target.id === "memberStatusNo" && event.target.value !== "on")
    });
  };

  handleNotificationChange = event => {
    this.setState({
      notifications:
        (event.target.id === "notificationsYes" &&
          event.target.value === "on") ||
        (event.target.id === "notificationsNo" && event.target.value !== "on")
    });
  };

  render = () => (
    <div className="Golfer">
      {this.state.noGolfer ? (
        <Alert bsStyle="info">
          <p>
            To have a playing profile created that includes handicap details and
            your membership status please email James @{" "}
            <a href="mailto:jimmy.brown98@gmail.com">jimmy.brown98@gmail.com</a>
            .
          </p>
        </Alert>
      ) : (
        <form>
          <FormAlert formError={this.state.formError} />
          <h3>Golfers Details</h3>
          <FormRow
            controlId="email"
            errors={this.state.errors}
            label="Email"
            value={this.state.email}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            regex={emailRegex}
            disabled={this.state.self}
            validationMessage="Please enter a valid email."
          />
          <FormRow
            controlId="givenName"
            errors={this.state.errors}
            label="Given Name"
            value={this.state.givenName}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            regex={lettersRegex}
            validationMessage="Please enter a given name."
          />
          <FormRow
            controlId="familyName"
            errors={this.state.errors}
            label="Family Name"
            value={this.state.familyName}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            regex={lettersRegex}
            validationMessage="Please enter a family name."
          />
          <FormRow
            controlId="nickname"
            label="Nickname (Optional)"
            value={this.state.nickname}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="gender"
            errors={this.state.errors}
            label="Gender"
            value={this.state.gender}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            regex={lettersRegex}
            validationMessage="Please select a gender."
          >
            <option value="">Please select...</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="N">Neutral</option>
          </FormRow>
          <FormGroup>
            <ControlLabel>Receive App Notifications?</ControlLabel>
            <Radio
              id="notificationsYes"
              name="notificationsRadioGroup"
              checked={this.state.notifications}
              onChange={this.handleNotificationChange}
            >
              Yes please
            </Radio>
            <Radio
              id="notificationsNo"
              name="notificationsRadioGroup"
              checked={!this.state.notifications}
              onChange={this.handleNotificationChange}
            >
              No thanks
            </Radio>
          </FormGroup>
          {this.state.self ? (
            <FormGroup>
              <ControlLabel>Handicap</ControlLabel>
              <div>{this.state.handicap}</div>
            </FormGroup>
          ) : (
            <FormRow
              controlId="handicap"
              errors={this.state.errors}
              label="Handicap"
              value={this.state.handicap}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              regex={numbersOnlyRegex}
              validationMessage="Please enter a valid handicap."
            />
          )}
          {this.state.self ? null : (
            <FormGroup>
              <ControlLabel>Membership Status</ControlLabel>
              <Radio
                id="memberStatusYes"
                name="memberStatusRadioGroup"
                checked={this.state.memberStatus}
                onChange={this.handleMemberChange}
              >
                Member
              </Radio>
              <Radio
                id="memberStatusNo"
                name="memberStatusRadioGroup"
                checked={!this.state.memberStatus}
                onChange={this.handleMemberChange}
              >
                Guest
              </Radio>
            </FormGroup>
          )}
          <FormSubmitButtons
            disabled={!this.validateForm()}
            isLoading={this.state.isLoading}
            label={this.state.self ? "My Details" : "Golfer"}
            entityId={this.state.id}
            self={this.state.self}
            isDeleting={this.state.isDeleting}
            onDeleteClick={this.handleDelete}
            onSubmit={this.handleSubmit.bind(this)}
          />
        </form>
      )}
    </div>
  );
}

export default withRouter(Golfer);
