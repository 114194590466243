import React, { Component } from "react";
import * as API from "API";
import { withRouter } from "react-router-dom";
import ThreeBallSixesSummary from "components/three-ball-sixes-summary/ThreeBallSixesSummary";

class ThreeBallSixesScorecard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      round: null,
      golfer: null
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);

      const scores = await API.getScores(null, this.props.match.params.id);

      this.props.setLoading(false);

      const rd = scores.rounds[0];

      let group = null;

      for (let grp of rd.groups) {
          if (grp.nbr == this.props.match.params.groupNumber) {
            group = grp;
            break;
          }
      }

      console.log(scores);

      const state = {
        round: rd,
        group: group
      };


      this.setState(state);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  getScoreTotals = (golfer, type, field) => {
    const tee = this.state.round.course.tees[this.state.round.courseTee];
    let front = 0,
      back = 0,
      golferScore = null;
    tee.holes.forEach(hole => {
      golferScore = hole[golfer.id];
      if (golferScore && golferScore[type]) {
        if (hole.nbr <= 9) {
          front = front + (golferScore[type] ? golferScore[type] * 1 : 0);
        } else {
          back = back + (golferScore[type] ? golferScore[type] * 1 : 0);
        }
      }
    });

    const values = {
      total: front + back === 0 ? null : front + back,
      front: front === 0 ? null : front,
      back: back === 0 ? null : back
    };

    return values[field];
  };

  render = () => (
    <div className="ThreeBallSixesScorecard">
      {this.state.round && this.state.group ? (
        <div>
          <ThreeBallSixesSummary
            homeClick={e =>
              this.props.history.push("/round/summary/" + this.state.round.id)
            }
            homeName={
              this.state.round.course.courseName +
              " - " +
              this.state.round.roundDate
            }
            name={
              this.state.group.name
            }
            tee={this.state.round.course.tees[this.state.round.courseTee]}
            group={this.state.group}
            course={this.state.round.course}
            totals={this.getScoreTotals}
          />
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(ThreeBallSixesScorecard);
