export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const upperRegex = /[A-Z]/g;
export const numberRegex = /[0-9]/g;
export const lettersRegex = /[a-zA-Z]+/g;
export const numbersOnlyRegex = /^[0-9,]+(\.[0-9]+)?$/;

export function validateInput(regex, value, props) {
  try {
    let valid =
      regex == null || regex === ""
        ? true
        : value.match(new RegExp(regex.substring(1, regex.lastIndexOf("/"))));

    if (valid && props) {
      if (props.min && value * 1 < props.min * 1) {
        return false;
      }
      if (props.max && value * 1 > props.max * 1) {
        return false;
      }
    }
    return valid;
  } catch (e) {
    return false;
  }
}

export function getOrdinalSuffix(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}
