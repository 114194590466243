import React from "react";
import { Table } from "react-bootstrap";
import "./TeamScoresByRound.css";

export default props => (
  <Table className="TeamScoresByRound" striped bordered condensed>
    <thead>
      <tr>
        <th className="round-col">#</th>
        <th className="team-col">
          {props.data[0].position === 1 ? (
            <span className="fas fa-trophy gold" />
          ) : props.data[0].position === 2 ? (
            <span className="fas fa-trophy silver" />
          ) : null}
          {props.data[0].name}
        </th>
        <th className="team-col">
          {props.data[1].position === 1 ? (
            <span className="fas fa-trophy gold" />
          ) : props.data[1].position === 2 ? (
            <span className="fas fa-trophy silver" />
          ) : null}
          {props.data[1].name}
        </th>
      </tr>
    </thead>
    <tbody>
      {[{}].concat(props.rounds(props.data[0], props.data[1], props.field)).map(
        (item, idx) =>
          idx !== 0 ? (
            <tr key={idx}>
              <td>{item.round}</td>
              <td>{item.a}</td>
              <td>{item.b}</td>
            </tr>
          ) : null
      )}
      <tr>
        <td className="total" />
        <td className="total">{props.data[0][props.totalField]}</td>
        <td className="total">{props.data[1][props.totalField]}</td>
      </tr>
    </tbody>
  </Table>
);
