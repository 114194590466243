import React, { Component } from "react";
import * as API from "API";
import { withRouter } from "react-router-dom";
import {
  Button,
  Breadcrumb,
  ListGroupItem,
  Grid,
  Row,
  Col,
  Table
} from "react-bootstrap";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import FormSubmitButtons from "components/form-submit-buttons/FormSubmitButtons";
import { lettersRegex, numbersOnlyRegex, validateInput } from "libs/formUtils";
import "./Course.css";

class Course extends Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.state = {
      courseName: "",
      measuredIn: "",
      selectedTee: "",
      tees: {
        white: this.getDefault18Holes(),
        yellow: this.getDefault18Holes(),
        red: this.getDefault18Holes()
      }
    };
  }

  componentWillMount = async () => {
    if (!this.props.isAdmin) {
      this.props.history.replace("/");
    }
  };

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const results = await API.getCourse(this.props.match.params.id);
      this.props.setLoading(false);
      const course = {
        id: results.id || "",
        courseName: results.courseName || "",
        measuredIn: results.measuredIn || "Yards",
        tees: {
          white:
            results.tees && results.tees.white
              ? results.tees.white
              : this.getDefault18Holes(),
          yellow:
            results.tees && results.tees.yellow
              ? results.tees.yellow
              : this.getDefault18Holes(),
          red:
            results.tees && results.tees.red
              ? results.tees.red
              : this.getDefault18Holes()
        }
      };
      this.setState(course);
    } catch (e) {
      this.props.setError(true);
    }
  };

  getDefault18Holes = () => {
    let tee = { sss: "", valid: false, holes: [] },
      number = 18;
    for (var i = 1; i <= number; i++) {
      tee.holes.push({
        nbr: i,
        len: "",
        par: "",
        si: ""
      });
    }
    return tee;
  };

  validateForm = () => {
    const white = this.validateTee("white"),
      yellow = this.validateTee("yellow"),
      red = this.validateTee("red");
    return (
      this.state.courseName !== "" &&
      this.state.measuredIn !== "" &&
      (white.valid || yellow.valid || red.valid)
    );
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSSSChange = event => {
    let tee = { ...this.state.tees[this.state.selectedTee] };
    tee.sss = event.target.value;
    this.setState({
      tees: { ...this.state.tees, [this.state.selectedTee]: tee }
    });
  };

  handleHoleChange = (nbr, type, value) => {
    let tee = { ...this.state.tees[this.state.selectedTee] };
    const hole = tee.holes.filter(hole => hole.nbr === nbr)[0];
    hole[type] = value;
    this.setState({
      tees: { ...this.state.tees, [this.state.selectedTee]: tee }
    });

    if (value) {
      value = value * 1;
      if (type === "len") {
        if (value > 80) {
          document.getElementById(nbr + "_par").focus();
        }
      } else if (type === "par") {
        if (value >= 3 && value <= 5) {
          document.getElementById(nbr + "_si").focus();
        }
      } else if (type === "si") {
        if (value >= 2 && value <= 18 && nbr * 1 !== 18) {
          document.getElementById(nbr + 1 + "_len").focus();
        }
      }
    }
  };

  handleTeeClick = tee => {
    this.setState({
      selectedTee: tee
    });
    window.scrollTo(0, 0);
  };

  handleFocus = event => {
    let errors = { ...this.state.errors };
    errors[event.target.id] = null;
    this.setState({
      errors: errors,
      formError: false
    });
  };

  handleBlur = event => {
    const regex = event.target.attributes.getNamedItem("data-regex");
    let errors = { ...this.state.errors };
    errors[event.target.id] = !validateInput(
      regex ? regex.value : null,
      this.state[event.target.id]
    )
      ? "error"
      : null;

    this.setState({
      errors: errors
    });
  };

  handleHoleBlur = (nbr, type, value) => {
    let tee = { ...this.state.tees[this.state.selectedTee] },
      errors = { ...this.state.errors },
      valid = true;
    const hole = tee.holes.filter(hole => hole.nbr === nbr)[0];

    if (type === "len") {
      valid = hole.len.match(numbersOnlyRegex) && hole.len * 1 > 0;
    } else if (type === "par") {
      valid =
        hole.par.match(numbersOnlyRegex) &&
        (hole.par === "3" || hole.par === "4" || hole.par === "5");
    } else if (type === "si") {
      valid =
        hole.si.match(numbersOnlyRegex) && hole.si * 1 > 0 && hole.si * 1 <= 18;
    }
    errors[nbr + "_" + type] = valid ? null : "error";
    this.setState({
      errors: errors
    });
  };

  handleSSSBlur = event => {
    const regex = event.target.attributes.getNamedItem("data-regex"),
      min = event.target.attributes.getNamedItem("min"),
      max = event.target.attributes.getNamedItem("max");
    let errors = { ...this.state.errors };
    errors[event.target.id] = !validateInput(
      regex ? regex.value : null,
      this.state.tees[this.state.selectedTee].sss,
      { min: min.value, max: max.value }
    )
      ? "error"
      : null;

    this.setState({
      errors: errors
    });
  };

  handleSubmit = async event => {
    const white = this.validateTee("white");
    const yellow = this.validateTee("yellow");
    const red = this.validateTee("red");
    let course = {
      courseName: this.state.courseName,
      measuredIn: this.state.measuredIn,
      tees: {}
    };
    if (white.valid) {
      course.tees.white = this.state.tees.white;
    }
    if (yellow.valid) {
      course.tees.yellow = this.state.tees.yellow;
    }
    if (red.valid) {
      course.tees.red = this.state.tees.red;
    }
    this.setState({ isLoading: true });
    try {
      if (this.props.match.params.id === "new") {
        await API.createCourse(course);
      } else {
        await API.saveCourse(this.state.id, course);
      }
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isLoading: false, formError: e.message });
    }
  };

  handleDelete = async event => {
    event.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete this course?"
    );
    if (!confirmed) {
      return;
    }
    this.setState({ isDeleting: true });
    try {
      await API.deleteCourse(this.state.id);
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isDeleting: false, formError: e.message });
    }
  };

  validateTee = colour => {
    const tee = this.state.tees[colour],
      validation = {
        empty: true,
        siDuplicate: false,
        sssInvalid: false,
        invalidHole: false
      },
      siMap = {};
    for (var i = 0; i < tee.holes.length; i++) {
      const hole = tee.holes[i];
      if (siMap[hole.si]) {
        validation.siDuplicate = true;
      }
      siMap[hole.si] = true;
      if (!hole.len.match(numbersOnlyRegex) || !hole.len * 1 > 0) {
        validation.invalidHole = true;
      }
      if (
        !hole.par.match(numbersOnlyRegex) ||
        !(hole.par === "3" || hole.par === "4" || hole.par === "5")
      ) {
        validation.invalidHole = true;
      }
      if (
        !hole.si.match(numbersOnlyRegex) ||
        !(hole.si * 1 > 0 && hole.si * 1 <= 18)
      ) {
        validation.invalidHole = true;
      }
      if (hole.len.length > 0 || hole.par.length > 0 || hole.si.length > 0) {
        validation.empty = false;
      }
    }
    if (!tee.sss.match(numbersOnlyRegex) || tee.sss < 20 || tee.sss > 80) {
      validation.sssInvalid = true;
    }
    if (tee.sss.length > 0) {
      validation.empty = false;
    }
    validation.valid =
      !validation.sssInvalid &&
      !validation.invalidHole &&
      !validation.siDuplicate &&
      !validation.empty;

    return validation;
  };

  getTeeSummary = colour => {
    const validation = this.validateTee(colour);
    if (validation.valid) {
      return (
        this.getTotals(colour, "len", "total") +
        " " +
        this.state.measuredIn +
        " / Par " +
        this.getTotals(colour, "par", "total")
      );
    } else if (!validation.empty) {
      return "Incomplete or invalid scorecard";
    }
  };

  getTotals = (colour, type, field) => {
    const tee = this.state.tees[colour];
    let front = 0,
      back = 0;
    tee.holes.forEach(hole => {
      if (hole.nbr <= 9) {
        front = front + (hole[type] ? hole[type] * 1 : 0);
      } else {
        back = back + (hole[type] ? hole[type] * 1 : 0);
      }
    });

    const values = {
      total: front + back === 0 ? null : front + back,
      front: front === 0 ? null : front,
      back: back === 0 ? null : back
    };

    return values[field];
  };

  renderTeeForm = tee => (
    <form>
      <FormRow
        controlId="sss"
        errors={this.state.errors}
        label="SSS"
        value={this.state.tees[this.state.selectedTee].sss}
        onChange={this.handleSSSChange}
        onFocus={this.handleFocus}
        onBlur={this.handleSSSBlur}
        validationMessage="Please enter a valid SSS."
        regex={numbersOnlyRegex}
        type="number"
        min="20"
        max="80"
      />
      <Button
        block
        bsStyle="primary"
        bsSize="large"
        onClick={e => this.setState({ selectedTee: null })}
      >
        Done
      </Button>
    </form>
  );

  renderHoles = (tee, nine) =>
    [{}].concat(tee.holes).map((hole, i) =>
      (i !== 0 && i < 10 && nine === "front") ||
      (i !== 0 && i > 9 && nine === "back") ? (
        <tr key={hole.nbr}>
          <td>{hole.nbr}</td>
          <td>
            <FormRow
              controlId={hole.nbr + "_len"}
              value={hole.len}
              errors={this.state.errors}
              onChange={e =>
                this.handleHoleChange(hole.nbr, "len", e.target.value)
              }
              onFocus={this.handleFocus}
              onBlur={e => this.handleHoleBlur(hole.nbr, "len", e.target.value)}
              type="number"
              min="1"
              max="1000"
              validationMessage="Please enter a valid hole length."
            />
          </td>
          <td>
            <FormRow
              controlId={hole.nbr + "_par"}
              value={hole.par}
              errors={this.state.errors}
              onChange={e =>
                this.handleHoleChange(hole.nbr, "par", e.target.value)
              }
              onFocus={this.handleFocus}
              onBlur={e => this.handleHoleBlur(hole.nbr, "par", e.target.value)}
              type="number"
              min="3"
              max="5"
              validationMessage="Please enter a valid par."
            />
          </td>
          <td>
            <FormRow
              controlId={hole.nbr + "_si"}
              value={hole.si}
              errors={this.state.errors}
              onChange={e =>
                this.handleHoleChange(hole.nbr, "si", e.target.value)
              }
              onFocus={this.handleFocus}
              onBlur={e => this.handleHoleBlur(hole.nbr, "si", e.target.value)}
              type="number"
              min="1"
              max="18"
              validationMessage="Please enter a valid stroke index."
            />
          </td>
        </tr>
      ) : null
    );

  renderHoleTable = (tee, nine) => (
    <Table striped bordered condensed className="holes-table">
      <thead>
        <tr>
          <th>Hole</th>
          <th>{this.state.measuredIn}</th>
          <th>Par</th>
          <th>S.I.</th>
        </tr>
      </thead>
      <tbody>
        {this.renderHoles(this.state.tees[tee], nine)}
        <tr key={"hole-sub-total"}>
          <td>{nine === "front" ? "FRONT" : "BACK"}</td>
          <td>
            {this.getTotals(
              this.state.selectedTee,
              "len",
              nine === "front" ? "front" : "back"
            )}
          </td>
          <td>
            {this.getTotals(
              this.state.selectedTee,
              "par",
              nine === "front" ? "front" : "back"
            )}
          </td>
          <td />
        </tr>
        {nine === "back" ? (
          <tr key={"hole-total"}>
            <td>{"TOTAL"}</td>
            <td>{this.getTotals(this.state.selectedTee, "len", "total")}</td>
            <td>{this.getTotals(this.state.selectedTee, "par", "total")}</td>
            <td />
          </tr>
        ) : null}
      </tbody>
    </Table>
  );

  renderInitialForm = () => (
    <form>
      <FormAlert formError={this.state.formError} />
      <h3>Course Details</h3>
      <FormRow
        controlId="courseName"
        errors={this.state.errors}
        label="Course Name"
        value={this.state.courseName}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        regex={lettersRegex}
        validationMessage="Please enter a course name."
      />
      <FormRow
        componentClass="select"
        placeholder="select"
        controlId="measuredIn"
        errors={this.state.errors}
        label="Distances Measured In"
        value={this.state.measuredIn}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        regex={lettersRegex}
        validationMessage="Please select what the course is measured in on the scorecard."
      >
        <option value="Yards">Yards</option>
        <option value="Meters">Meters</option>
      </FormRow>
      {this.state.courseName.length > 0 ? (
        <div className="tee-list">
          <ListGroupItem
            className="white-tee"
            key={"white_tee"}
            onClick={() => this.handleTeeClick("white")}
            header={"White"}
          >
            {this.getTeeSummary("white")}
          </ListGroupItem>
          <ListGroupItem
            className="yellow-tee"
            key={"yellow_tee"}
            onClick={() => this.handleTeeClick("yellow")}
            header={"Yellow"}
          >
            {this.getTeeSummary("yellow")}
          </ListGroupItem>
          <ListGroupItem
            className="red-tee"
            key={"red_tee"}
            onClick={() => this.handleTeeClick("red")}
            header={"Red"}
          >
            {this.getTeeSummary("red")}
          </ListGroupItem>
        </div>
      ) : null}
      <FormSubmitButtons
        disabled={!this.validateForm()}
        isLoading={this.state.isLoading}
        label="Course"
        entityId={this.state.id}
        isDeleting={this.state.isDeleting}
        onDeleteClick={this.handleDelete}
        onSubmit={this.handleSubmit.bind(this)}
      />
    </form>
  );

  renderSelectedTee = () => (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item onClick={e => this.setState({ selectedTee: null })}>
          {this.state.courseName}
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="tee-breadcrumb">
          {this.state.selectedTee}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Grid className="holes-container">
        <Row className="show-grid">
          <Col md={6}>
            {this.renderHoleTable(this.state.selectedTee, "front")}
          </Col>
          <Col md={6}>
            {this.renderHoleTable(this.state.selectedTee, "back")}
          </Col>
        </Row>
        <Row>
          <Col md={12}>{this.renderTeeForm()}</Col>
        </Row>
      </Grid>
    </div>
  );

  render = () => (
    <div className="Course">
      {this.state.selectedTee
        ? this.renderSelectedTee()
        : this.renderInitialForm()}
    </div>
  );
}

export default withRouter(Course);
