import { Auth, API, Storage } from "aws-amplify";
import {v4 as uuid} from "uuid"; 

export async function s3Upload(path, files) {
  let keys = [];
  let i = 1;
  for (let file of files) {
    let filename = uuid() + i;
    const stored = await Storage.vault.put(path + filename, file, {
      contentType: file.type,
      level: "public",
    });
    keys.push(stored.key);
    i = i + 1;
  }
  return keys;
}

export async function getS3UploadURLs(keys) {
  let attachmentURLs = [];
  for (let attachment of keys) {
    const attachmentURL = await Storage.get(attachment, { expires: 3600 });
    attachmentURLs.push({
      id: attachment,
      url: attachmentURL,
    });
  }
  return attachmentURLs;
}

export async function getS3URL(key) {
  return await Storage.get(key, { expires: 3600 });
}

export async function invokeApig({
  endpoint,
  path,
  method = "GET",
  headers = {},
  queryParams = {},
  body,
}) {
  let localStorageKey = path + "?";
  if (queryParams) {
    for (let key in queryParams) {
      localStorageKey = localStorageKey + key + "=" + queryParams[key] + "&";
    }
  }

  const cache =
    method === "GET"
      ? JSON.parse(window.localStorage.getItem(localStorageKey))
      : null;

  if (cache && cache.resp && cache.cacheTime) {
    queryParams.cacheTime = cache.cacheTime;
  }

  let myInit = {
    headers: {},
    queryStringParameters: queryParams,
    body: body,
  };

  let responsePromise;

  try {
    if (method === "GET") {
      if (navigator.onLine === false && cache && cache.resp) {
        return cache.resp;
      } else {
        responsePromise = API.get(endpoint, path, myInit);
      }
    } else if (method === "POST") {
      responsePromise = API.post(endpoint, path, myInit);
    } else if (method === "PUT") {
      responsePromise = API.put(endpoint, path, myInit);
    } else if (method === "DELETE") {
      responsePromise = API.del(endpoint, path, myInit);
    }
  } catch (e) {
    if (cache && cache.resp) {
      return cache.resp;
    } else {
      throw new Error(e);
    }
  }

  // Have to resove GET requests in order to know whether to use cache or not
  if (method === "GET") {
    if (cache && cache.resp) {
      const json = await responsePromise;
      if (json.useCache) {
        return cache.resp;
      } else {
        return cacheResults(localStorageKey, responsePromise);
      }
    } else {
      return cacheResults(localStorageKey, responsePromise);
    }
  }
  // return promise for non GET requests
  return responsePromise;
}

async function cacheResults(localStorageKey, jsonPromise) {
  const json = await jsonPromise;
  const cache = {
    cacheTime: json.updatedAt,
    resp: json.resp,
  };
  try {
    window.localStorage.setItem(localStorageKey, JSON.stringify(cache));
  } catch (e) {
    console.log("Error setting local storage - " + localStorageKey);
  }
  return json.resp;
}

export async function getLoggedInEmail() {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser && currentUser.attributes) {
      return currentUser.attributes.email;
    } else {
      // Load from FB instead
      await waitForFBInit();
      const response = await getLoginStatus();
      if (response.status === "connected") {
        const fields = await getFBField(response.authResponse.userID, "email");
        return fields.email;
      }
    }
  } catch (e) {
    console.log(e);
  }
  return null;
}

export function waitForFBInit() {
  return new Promise((res, rej) => {
    const hasFbLoaded = () => {
      if (window.FB) {
        res();
      } else {
        setTimeout(hasFbLoaded, 300);
      }
    };
    hasFbLoaded();
  });
}

export const getFBField = (id, fields) => {
  return new Promise(async (resolve) => {
    window.FB.api("/" + id + "/?fields=" + fields, function (response) {
      resolve(response);
    });
  });
};

const getLoginStatus = () => {
  return new Promise(async (resolve) => {
    window.FB.getLoginStatus((response) => {
      resolve(response);
    });
  });
};

export async function isInAdminGroup() {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();

    if (currentUser && currentUser.signInUserSession) {
      const groups =
        currentUser.signInUserSession.accessToken.payload["cognito:groups"];
      return groups && groups.length > 0 && groups[0] === "admin";
    }
  } catch (e) {
    console.log(e);
  }
  return false;
}
