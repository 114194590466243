import { invokeApig } from "../libs/awsLib";

const apiName = "scores";

// Scores API
export const getScores = (competitionId, roundId) => {
  let queryParams = {};
  if (competitionId) {
    queryParams.competitionId = competitionId;
  }
  if (roundId) {
    queryParams.roundId = roundId;
  }
  return invokeApig({
    endpoint: apiName,
    path: "/scores",
    queryParams: queryParams
  });
};
