export const GENERIC_CONTENT = {
  SOCIETY_NAME: "Bat & Ball Golf",
  LANDING_HEADING: "Welcome to Bat & Ball Golf",
  LANDING_SUB_HEADING: "Quality Courses, Average Golfers.",
  LANDING_TEXT:
    "Login or Signup to view the upcoming society schedule, competition summaries, round scores and photos."
};

export const PRIVACY_POLICY_CONTENT = {
  HEADING: "Privacy Policy"
};

export const NEWS_CONTENT = {
  HEADING: "News",
  SUB_HEADING: "Spread the word...",
  HEADING_ICON: "fas fa-newspaper"
};

export const ANNUAL_STATS_CONTENT = {
  HEADING: "Annual Stats",
  SUB_HEADING: "How we all did in years gone by...",
  HEADING_ICON: "fas fa-chart-bar"
};

export const GOLFERS_CONTENT = {
  HEADING: "Golfers",
  SUB_HEADING: "Sort of...",
  HEADING_ICON: "fas fa-golf-ball"
};

export const HANDICAPS_CONTENT = {
  HEADING: "Handicaps",
  SUB_HEADING: "What we moan about...",
  HEADING_ICON: "fas fa-universal-access"
};

export const COURSES_CONTENT = {
  HEADING: "Courses",
  SUB_HEADING: "Where we play...",
  HEADING_ICON: "fas fa-tree"
};

export const COMPETITIONS_CONTENT = {
  HEADING: "Competitions",
  SUB_HEADING: "And the glory that comes with them...",
  HEADING_ICON: "fas fa-trophy"
};

export const ROUNDS_CONTENT = {
  HEADING: "Rounds",
  SUB_HEADING: "The good and the bad...",
  HEADING_ICON: "fas fa-map-marker-alt"
};

export const HONOURS_CONTENT = {
  HEADING: "Honours",
  SUB_HEADING: "Winners & runners up...",
  HEADING_ICON: "fas fa-medal"
};

export const APPEARANCES_CONTENT = {
  HEADING: "Appearances",
  SUB_HEADING: "More comps to be added soon...",
  HEADING_ICON: "fas fa-list-ol"
};

export const CALENDAR_CONTENT = {
  HEADING: "2020 Calendar",
  SUB_HEADING: "Provsional schedule for the year.",
  HEADING_ICON: "fas fa-calendar-alt",
  NEXT_YEAR: "2020"
};

export const COMMITTEE_CONTENT = {
  HEADING: "Our Committee",
  SUB_HEADING: "Meet the team for 2019.",
  HEADING_ICON: "fas fa-users"
};

export const RULES_CONTENT = {
  HEADING: "The Rules",
  SUB_HEADING: "What we get confused about.",
  HEADING_ICON: "fas fa-graduation-cap"
};
