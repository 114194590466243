import React from "react";
import { Table } from "react-bootstrap";
import "./TeamScoresForRound.css";

export default props => (
  <Table className="TeamScoresForRound" striped bordered condensed>
    <thead>
      <tr>
        <th className="team-col">{props.teamA}</th>
        <th className="v-col">V</th>
        <th className="team-col">{props.teamB}</th>
      </tr>
    </thead>
    <tbody>
      {[{}].concat(props.data).map(
        (item, idx) =>
          idx !== 0 ? (
            <tr key={idx} className={item.buffer ? "buffer-row" : ""}>
              <td className={"team-col " + item.aResult}>{item.a}</td>
              <td className="v-col">{item.hasV ? "V" : ""}</td>
              <td className={"team-col " + item.bResult}>{item.b}</td>
            </tr>
          ) : null
      )}
    </tbody>
  </Table>
);
