import React, { Component } from "react";
import * as API from "API";
import { Row, Col } from "react-bootstrap";
import News from "components/news/News";
import HandicapChart from "components/handicap-chart/HandicapChart";
import BadgesList from "components/badges-list/BadgesList";
import ReigningChampions from "components/reigning-champions/ReigningChampions";
import AggregatedRoundStats from "components/aggregated-round-stats/AggregatedRoundStats";
import AggregatedCompetitionStats from "components/aggregated-competition-stats/AggregatedCompetitionStats";
import { Link } from "react-router-dom";
import { getLoggedInEmail } from "libs/awsLib";
import { withRouter } from "react-router-dom";
import { formatHandicapListForGolfer } from "libs/handicapUtils";
import { formatRoundStats } from "libs/roundStatsHelper";
import Photos from "components/photos/Photos";
import "./Home.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeYear: 2019,
      email: "",
      givenName: "",
      handicap: "",
      memberStatus: false,
      competitions: [],
      roundHandicaps: [],
      roundStats: { noOfRounds: 1 },
      competitionStats: {},
      resizeUpdater: this.updateChartWidth.bind(this)
    };
  }
  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const competitionsPromise = API.competitionsLite();
      const roundHandicapsPromise = API.handicapsByYear(this.state.activeYear);
      const golferResp = await this.getGolfer(this.props.match.params.id);

      const golferDetailsPromise = API.golferDetails(golferResp.id);

      const competitionsResp = await competitionsPromise;
      let roundHandicaps = await roundHandicapsPromise;
      const golferDetailsResp = await golferDetailsPromise;
      let news;

      const filteredList = formatHandicapListForGolfer(
        golferResp.id,
        roundHandicaps
      );

      if (golferDetailsResp.stats && !this.props.match.params.id) {
        news = await API.content("news");
      }

      this.props.setLoading(false);

      const chartWidth =
        document.getElementsByClassName("right-section").length > 0
          ? document.getElementsByClassName("right-section")[0].offsetWidth - 30
          : 0;

      const golfer = {
        pathId: this.props.match.params.id,
        id: golferResp.id || "",
        email: golferResp.email || "",
        givenName: golferResp.givenName || "",
        familyName: golferResp.familyName || "",
        handicap: golferResp.handicap || "",
        competitions: competitionsResp,
        roundStats: golferDetailsResp.stats
          ? golferDetailsResp.stats.roundStats
          : null,
        competitionStats: golferDetailsResp.stats
          ? golferDetailsResp.stats.competitionStats
          : null,
        roundHandicaps: filteredList,
        chartWidth: chartWidth,
        badges: golferDetailsResp.badges,
        news: news
      };
      this.setState(golfer);

      window.addEventListener("resize", this.state.resizeUpdater);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.state.resizeUpdater);
  };

  updateChartWidth = () => {
    const chartWidth =
      document.getElementsByClassName("right-section").length > 0
        ? document.getElementsByClassName("right-section")[0].offsetWidth - 30
        : 0;
    this.setState({
      chartWidth: chartWidth
    });
  };

  getGolfer = async id => {
    if (!id) {
      const email = await getLoggedInEmail();
      const response = await API.getGolferByEmail(email);
      return response && response.length > 0 ? response[0] : { noGolfer: true };
    } else {
      return API.getGolfer(id);
    }
  };

  filterBadges = best => {
    let filtered = this.state.badges.filter(item => item.earned);
    return best ? this.sortBadges(filtered) : filtered;
  };

  formatRoundStats = () => {
    return formatRoundStats({ ...this.state.roundStats });
  };

  sortBadges = result => {
    // Returns newest first
    return result.sort((a, b) => {
      const rankA = this.getBadgeRank(a),
        rankB = this.getBadgeRank(b);
      return rankA > rankB ? 1 : rankA < rankB ? -1 : 0;
    });
  };

  getBadgeRank = badge => {
    return badge.type === "rare"
      ? 1
      : badge.tier === 1 || badge.type === "win"
      ? 2
      : !badge.tier && !badge.bad
      ? 3
      : badge.tier === 2
      ? 4
      : badge.tier === 3
      ? 5
      : !badge.bad === 3
      ? 6
      : 7;
  };

  render = () => (
    <div className="Home">
      {this.state.roundStats || this.state.pathId ? (
        <div>
          <h3 className="GolferTitle">
            {this.state.givenName + " " + this.state.familyName}
            <span className="handicap-title-dash"> - </span>
            <Link className="handicap-title" to={"/handicaps/" + this.state.id}>
              {this.state.handicap} HCP
            </Link>
          </h3>
          <h4>
            <Link
              to={
                this.props.match.params.id &&
                this.props.match.params.id !== this.props.currentUser.id
                  ? "/annual-stats/" + this.props.match.params.id
                  : "/annual-stats/"
              }
            >
              Annual Stats
            </Link>
          </h4>
          {this.state.roundStats &&
          this.state.roundStats.noOfRounds &&
          this.state.roundStats.noOfRounds > 0 ? (
            <Row>
              <Col className="left-section col-xl-6">
                {this.state.pathId ? null : <News items={this.state.news} />}
                <AggregatedRoundStats
                  data={this.formatRoundStats()}
                  golferId={this.state.id}
                />
              </Col>
              <Col className="right-section col-xl-6">
                <hr className="first-line" />
                {this.state.roundHandicaps.length > 1 ? (
                  <div>
                    <HandicapChart
                      heading={"Handicap"}
                      data={this.state.roundHandicaps}
                      width={this.state.chartWidth}
                      golferId={this.state.id}
                      showAllLink={true}
                      startYear={this.state.activeYear}
                    />
                    <hr />
                  </div>
                ) : null}
                {this.state.badges ? (
                  <Row>
                    <Col className="best-badges col-md-6">
                      <BadgesList
                        badges={this.filterBadges(false)}
                        golferId={this.state.id}
                        heading={"My Recent Badges"}
                        limit={8}
                      />
                    </Col>
                    <Col className="recent-badges col-md-6">
                      <BadgesList
                        badges={this.filterBadges(true)}
                        golferId={this.state.id}
                        heading={"My Best Badges"}
                        limit={8}
                      />
                    </Col>
                  </Row>
                ) : null}
                <hr />
                <AggregatedCompetitionStats
                  data={this.state.competitionStats}
                />
                <hr />
                <ReigningChampions comps={this.state.competitions} />
              </Col>
            </Row>
          ) : (
            <h4>No stats to display.</h4>
          )}
        </div>
      ) : (
        <Photos
          folder="gallery"
          showUpload={
            this.props.currentUser && this.props.currentUser.memberStatus
              ? true
              : false
          }
        />
      )}
    </div>
  );
}

export default withRouter(Home);
