import React from "react";
import { Table } from "react-bootstrap";
import "./ScoresByRoundDetail.css";

export default props => (
  <div className="ScoresByRoundDetail">
    {[{}].concat(props.data).map(
      (item, idx) =>
        idx !== 0 && item && (!props.golferId || item.id === props.golferId) ? (
          <div key={idx}>
            <h4>
              #{" "}
              {item.position +
                " " +
                (item.tied ? "=" : "") +
                " - " +
                item.givenName +
                " " +
                item.familyName}
              {item.position === 1 ? (
                <span className="fas fa-trophy gold" />
              ) : item.position === 2 ? (
                <span className="fas fa-trophy silver" />
              ) : item.position === 3 ? (
                <span className="fas fa-trophy bronze" />
              ) : null}
            </h4>
            <Table striped bordered condensed>
              <thead>
                <tr>
                  <th className="round-col">Scoring</th>
                  <th className="score-col">Points</th>
                </tr>
              </thead>
              <tbody>
                {[{}].concat(props.roundBreakdown(item)).map(
                  (data, dataIdx) =>
                    dataIdx !== 0 ? (
                      <tr
                        key={dataIdx}
                        className={data.value === "" ? "round-row" : null}
                      >
                        <td>{data.label}</td>
                        <td>{data.value}</td>
                      </tr>
                    ) : null
                )}
                {props.totalField ? (
                  <tr>
                    <td className="total">Grand Total</td>
                    <td className="total">{item[props.totalField]}</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
            <hr />
          </div>
        ) : null
    )}
  </div>
);
