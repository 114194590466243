import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderButton from "components/loader-button/LoaderButton";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import { isInAdminGroup } from "libs/awsLib";
import * as API from "API";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  componentDidMount = () => {
    setTimeout(
      function () {
        try {
          this.setState({
            autocompleted:
              document.querySelectorAll("#email:-webkit-autofill").length > 0 &&
              document.querySelectorAll("#password:-webkit-autofill").length >
                0,
          });
        } catch (e) {
          console.log(e);
        }
      }.bind(this),
      400
    );
  };

  handleFbLogin = () => {
    this.props.setAuthenticated(true);
  };

  validateForm = () => {
    return (
      this.state.autocompleted ||
      (this.state.email.length > 0 && this.state.password.length > 0)
    );
  };

  handleFocus = (event) => {
    this.setState({
      autocompleted: false,
      loginError: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    this.setState({ isLoading: true });
    try {
      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);

      const isAdmin = await isInAdminGroup();
      this.props.setAdmin(isAdmin);

      const currentUser = await API.getGolferByEmail(
        this.state.email.toLowerCase()
      );
      this.props.setCurrentUser(
        currentUser && currentUser.length > 0 ? currentUser[0] : null
      );

      this.props.setAuthenticated(true);
    } catch (e) {
      this.setState({
        isLoading: false,
        loginError: e.message ? e.message : e,
      });
    }
  };

  render = () => (
    <div className="Login">
      <form>
        <FormAlert formError={this.state.loginError} />
        <FormRow
          autoFocus
          controlId="email"
          label="Email"
          value={this.state.email}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
        />
        <FormRow
          controlId="password"
          type="password"
          label="Password"
          value={this.state.password}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
        />
        <LoaderButton
          block
          bsSize="large"
          bsStyle="primary"
          disabled={!this.validateForm()}
          onClick={this.handleSubmit.bind(this)}
          isLoading={this.state.isLoading}
          text="Login"
          loadingText="Logging in…"
        />
        <FormGroup className="forgotten-password-link">
          <Link to="/forgotten-password">Forgotten Password?</Link>
        </FormGroup>
        <FormGroup className="signup-link">
          <div>Not signed up yet?</div>
          <Link className="btn btn-block btn-lg btn-default" to="/signup">
            Signup here
          </Link>
        </FormGroup>
      </form>
    </div>
  );
}
