import { invokeApig } from "../libs/awsLib";

const apiName = "photos";

export const createCompetitionTags = (competitionId, photos) =>
  invokeApig({
    endpoint: apiName,
    path: `/photos/competition/remove/${competitionId}`,
    method: "POST",
    body: photos
  });

export const getGalleryPhotos = () =>
  invokeApig({
    endpoint: apiName,
    path: "/photos/gallery"
  });
export const getCompetitionPhotos = competitionId =>
  competitionId && competitionId !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: `/photos/competition/${competitionId}`
      })
    : [];
export const getScorecardPhotos = roundId =>
  invokeApig({
    endpoint: apiName,
    path: `/photos/scorecard/${roundId}`
  });

export const deleteGalleryPhotos = photos =>
  invokeApig({
    endpoint: apiName,
    path: `/photos/gallery/remove`,
    method: "POST",
    body: photos
  });
export const deleteScorecardPhotos = (roundId, photos) =>
  invokeApig({
    endpoint: apiName,
    path: `/photos/scorecard/remove/${roundId}`,
    method: "POST",
    body: photos
  });
export const deleteCompetitionPhotos = (competitionId, photos) =>
  invokeApig({
    endpoint: apiName,
    path: `/photos/competition/remove/${competitionId}`,
    method: "POST",
    body: photos
  });
