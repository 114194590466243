import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import "./CompetitionRules.css";

class CompetitionRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: "",
      type: ""
    };
  }
  componentDidMount = async () => {
    try {
      var state = {
        year: this.props.match.params.year,
        type: this.props.match.params.type
      };
      this.setState(state);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  get2024batandballcupTrophyRules = () => {
    return (
      <div>
        <div>
          <h2>Bat & Ball Cup 2024 Format & Scoring</h2>
          <br></br>
          <h4>Individual Competition</h4>
          <div className="rules-content">
            <div>
              The individual competition will be based on combined stableford points from the best four out of five rounds.
              <div>
              <u>No Gimmes.</u>
              </div>
              <h5>Each Round</h5>
              £15 to the winner, £10 to the runner up.
              <br></br>
              <h5>Overall</h5>
              The Bat & Ball Cup and £50 to the winner, £30 to the runner up and £20 to third place.
            </div>
          </div>
          <hr />
          <h4>Team Game</h4>
          <div className="rules-content">
            <div>
              Team competition will be between three teams of three. For the first 4 rounds, each team of three will combine the best two stableford scores from each hole. <u>On the last round, all 3 scores are combined.</u> All scores are totalled up after each round and the winning team will be the one with the highest combined score over the five rounds.
              <br></br>
              <h5>Overall</h5>
              A prize for each team member at the end.
            </div>
          </div>
          <hr />
          <h4>Group Games</h4>
          <div className="rules-content">
            <div>
              As well as the individual and team competition, there will also be money to play for within each three ball.
              <div>The game is three ball sixes. Six points are awarded in total on each hole and these are totalled up for the round to give a winner.</div>
              <ul>
                <li>If a player has is an outright winner and an outright second place then it is four points to the winner and two to the second place.</li>
                <li>If two people tie for first it is three points each to them. </li>
                <li>If two people tie for second they get one point. </li>
                <li>If everyone has equal 'pts', then each get two points each.</li>
              </ul>
              <h5>Each Round (except the last)</h5>
              £10 to the winner of each 3-ball group.
            </div>
          </div>
          <hr />
          <h4>Par Three Champ</h4>
          <div className="rules-content">
            <div>
              Total points on all the par threes across all rounds. No joker holes.
              <h5>Overall</h5>
              A prize for the overall par 3 champ.
            </div>
          </div>
          <hr />
          <h4>Nearest the pin, Nearest the pin in two and Longest drive</h4>
          <div className="rules-content">
            <div>Played each round.</div>
            <h5>Each Round</h5>
            Sleeve of golf balls to each of the 3 winners each day.
          </div>
          <hr />
          <h4>Last place</h4>
          <div className="rules-content">
            <div>
              <h5>Overall</h5>
              The Turkey Trophy
            </div>
          </div>
          <hr />
          <h4>Prize Cost Breakdown</h4>
          <Table striped bordered condensed>
             <tbody>
                <tr>
                  <td>5 dozen bridgestone golf balls</td>
                  <td>£160</td>
                </tr>
                <tr>
                  <td>Winners trophy, engraving & plinth</td>
                  <td>£57</td>
                </tr>
                <tr>
                  <td>Turkey trophy</td>
                  <td>£18</td>
                </tr>
                <tr>
                  <td>Winning team prize (1 prize x 3 team members)</td>
                  <td>£65</td>
                </tr>
                <tr>
                  <td>Par three champ (1 prize for winner)</td>
                  <td>£30</td>
                </tr>
                <tr>
                  <td>Winners of each group (3 groups x 4 rounds x £10 cash)</td>
                  <td>£120</td>
                </tr>
                <tr>
                  <td>Round winners (5 rounds x £15 cash)</td>
                  <td>£75</td>
                </tr>
                <tr>
                  <td>Round runners up (5 rounds x £10 cash)</td>
                  <td>£50</td>
                </tr>
                <tr>
                  <td>Overall winner (cash)</td>
                  <td>£50</td>
                </tr>
                <tr>
                  <td>Overall runner-up (cash)</td>
                  <td>£30</td>
                </tr>
                <tr>
                  <td>Overall third place (cash)</td>
                  <td>£20</td>
                </tr>
                <tr>
                  <td><b>Grand total</b></td>
                  <td><b>£675</b></td>
                </tr>
            </tbody>
            </Table>
        </div>
      </div>
    );
  };
  render = () => {
    return (
      <section className="bg-light CompetitionRules">
        <div>
          <form>
            {this.state.year === "2024" &&
            this.state.type === "batandballcup" ? (
              this.get2024batandballcupTrophyRules()
            ) : (
              <h3>No rules to display for this year and competition.</h3>
            )}
          </form>
        </div>
      </section>
    );
  };
}

export default withRouter(CompetitionRules);
