export const formatRoundStats = roundStats => {
  let rangeProps = {};
  let allPoints = [],
    allScoresAgainstPar = [];

  roundStats.avPar3ToPar = (roundStats.avPar3 - 3).toFixed(2);
  roundStats.avPar4ToPar = (roundStats.avPar4 - 4).toFixed(2);
  roundStats.avPar5ToPar = (roundStats.avPar5 - 5).toFixed(2);

  if (roundStats.distanceAverages) {
    for (let key in roundStats.distanceAverages) {
      if (key.endsWith("sp")) {
        allPoints.push(roundStats.distanceAverages[key]);
      } else if (key.endsWith("s")) {
        const par = key.includes("3") ? 3 : key.includes("4") ? 4 : 5;
        const score = (roundStats.distanceAverages[key] - par).toFixed(2);

        allScoresAgainstPar.push(score);
        roundStats.distanceAverages[key + "ToPar"] = score;
      }
    }
    let max = Math.max(...allPoints),
      min = Math.min(...allPoints),
      dif = max - min,
      rangeDividor = dif / 3;

    for (let key in roundStats.distanceAverages) {
      if (key.endsWith("sp")) {
        const val = roundStats.distanceAverages[key];
        let range = "medium";
        if (val < min + rangeDividor) {
          range = "low";
        } else if (val > max - rangeDividor) {
          range = "high";
        }
        rangeProps[key + "Range"] = range;
      }
    }

    roundStats.avPar3PtsRange =
      roundStats.avPar3Pts < min + rangeDividor
        ? "low"
        : roundStats.avPar3Pts > max - rangeDividor
        ? "high"
        : "medium";
    roundStats.avPar4PtsRange =
      roundStats.avPar4Pts < min + rangeDividor
        ? "low"
        : roundStats.avPar4Pts > max - rangeDividor
        ? "high"
        : "medium";
    roundStats.avPar5PtsRange =
      roundStats.avPar5Pts < min + rangeDividor
        ? "low"
        : roundStats.avPar5Pts > max - rangeDividor
        ? "high"
        : "medium";

    max = Math.max(...allScoresAgainstPar);
    min = Math.min(...allScoresAgainstPar);
    dif = max - min;
    rangeDividor = dif / 3;

    for (let key in roundStats.distanceAverages) {
      if (key.endsWith("ToPar")) {
        const val = roundStats.distanceAverages[key];
        let range = "medium";
        if (val < min + rangeDividor) {
          range = "high";
        } else if (val > max - rangeDividor) {
          range = "low";
        }
        rangeProps[key + "Range"] = range;
      }
    }

    roundStats.avPar3ToParRange =
      roundStats.avPar3ToPar < min + rangeDividor
        ? "high"
        : roundStats.avPar3ToPar > max - rangeDividor
        ? "low"
        : "medium";
    roundStats.avPar4ToParRange =
      roundStats.avPar4ToPar < min + rangeDividor
        ? "high"
        : roundStats.avPar4ToPar > max - rangeDividor
        ? "low"
        : "medium";
    roundStats.avPar5ToParRange =
      roundStats.avPar5ToPar < min + rangeDividor
        ? "high"
        : roundStats.avPar5ToPar > max - rangeDividor
        ? "low"
        : "medium";
  }

  roundStats.distanceAverages = {
    ...roundStats.distanceAverages,
    ...rangeProps
  };
  return roundStats;
};
