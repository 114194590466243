import React from "react";
import "./Timeline.css";

const Timeline = props => <ul className="timeline">{props.children}</ul>;

const getClass = props => {
  let className = props.className;
  if (
    props.content.EXPIRED_DATE &&
    new Date() > new Date(props.content.EXPIRED_DATE)
  ) {
    className = className + " past-event";
  }
  return className;
};
const TimelineItem = props =>
  props.content.COMING_SOON ? (
    <li>
      <div className="timeline-image">
        <h4>
          {props.content.YEAR}
          <br />
          Coming
          <br />
          Soon!
        </h4>
      </div>
    </li>
  ) : (
    <li className={getClass(props)}>
      <div className="timeline-image">
        {props.img ? (
          <img className="rounded-circle img-fluid" src={props.img} alt="" />
        ) : props.content.NAME ? (
          <h4>
            <br />
            {props.content.NAME}
          </h4>
        ) : (
          ""
        )}
      </div>
      <div className="timeline-panel">
        <div className="timeline-heading">
          <h4>{props.content.TITLE}</h4>
          <h4 className="subheading">{props.content.DESCRIPTION}</h4>
          <h5 className="subheading">{props.content.DATE}</h5>
        </div>
        <div className="timeline-body">
          <p className="text-muted">{props.content.NARATIVE}</p>
        </div>
      </div>
    </li>
  );

export { Timeline, TimelineItem };
