import React, { Component } from "react";
import { Table, Grid, Col, Row, Breadcrumb } from "react-bootstrap";
import FormRow from "components/form-row/FormRow";
import LoaderButton from "components/loader-button/LoaderButton";
import "./ThreeBallSixesSummary.css";

class ThreeBallSixesSummary extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  renderHoleTable = nine => (
    <Table
      striped
      bordered
      condensed
      className="static holes-table"
    >
      <thead>
        <tr>
          <th className="hole-desc-col">Hole</th>
          <th className="hole-score-col">Strokes / Stb. Pts / 6s Pts</th>
        </tr>
      </thead>
      <tbody>
        {this.renderHoles(nine)}
        <tr className="total-row" key={"hole-sub-total"}>
          <td className="hole-desc-col">
            {nine === "front" ? "FRONT" : "BACK"}
          </td>
          <td className="hole-score-col">
              <table className="mini-table">
                <tbody>
                  {[{}]
                  .concat(this.props.group.golfers)
                  .map(
                    (golfer, golferIdx) =>
                      golferIdx !== 0
                        ? <tr key={golfer.id}>
                            <td className="name-td">{golfer.givenName + " " + golfer.familyName}</td>
                            <td className="scores-td strokes-td">
                              {this.props.totals(golfer, "score", nine)}
                            </td>
                            <td className="scores-td pts-td">{this.props.totals(golfer, "pts", nine)}</td>
                            <td className="scores-td three-ball-pts-td">{this.props.totals(golfer, "threeBallPts", nine)}</td>
                          </tr>
                        : null
                  )}
                </tbody>
              </table>
            </td>
        </tr>
        {nine === "back" ? (
          <tr className="total-row" key={"hole-total"}>
            <td className="hole-desc-col">TOTAL</td>
            <td className="hole-score-col">
              <table className="mini-table">
                <tbody>
                  {[{}]
                  .concat(this.props.group.golfers)
                  .map(
                    (golfer, golferIdx) =>
                      golferIdx !== 0
                        ? <tr key={golfer.id}>
                            <td className="name-td">{golfer.givenName + " " + golfer.familyName}</td>
                            <td className="scores-td strokes-td">
                              {this.props.totals(golfer, "score", "total")}
                            </td>
                            <td className="scores-td pts-td">{this.props.totals(golfer, "pts", "total")}</td>
                            <td className="scores-td three-ball-pts-td">{this.props.totals(golfer, "threeBallPts", "total")}</td>
                          </tr>
                        : null
                  )}
                </tbody>
              </table>
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );

  renderHoles = nine =>
    [{}].concat(this.props.tee.holes).map((hole, i) =>
      (i !== 0 && i < 10 && nine === "front") ||
      (i !== 0 && i > 9 && nine === "back") ? (
        <tr key={hole.nbr}>
          <td className="hole-desc-col">
            <div className="hole-desc">{hole.nbr}</div>
            <div>{"Par " + hole.par}</div>
            <div>{"SI " + hole.si}</div>
            <div>{hole.len + " " + this.props.course.measuredIn}</div>
          </td>
          <td className="hole-score-col">
            <table className="mini-table">
              <tbody>
                {[{}]
                .concat(this.props.group.golfers)
                .map(
                  (golfer, golferIdx) =>
                    golferIdx !== 0
                      ? <tr key={golfer.id}>
                          <td className="name-td">{golfer.givenName + " " + golfer.familyName}</td>
                          <td className="scores-td strokes-td">
                            <div className={"score-indicator" +
                                " " +
                                (hole[golfer.id]
                                  ? hole[golfer.id].pts === 5
                                    ? "albatross"
                                    : hole[golfer.id].pts === 4
                                    ? "eagle"
                                    : hole[golfer.id].pts === 3
                                    ? "birdie"
                                    : hole[golfer.id].pts === 2
                                    ? "par"
                                    : hole[golfer.id].pts === 1
                                    ? "bogey"
                                    : hole[golfer.id].pts === 0
                                    ? "double-bogey"
                                    : ""
                                  : "")
                              }
                            >
                              {hole[golfer.id].score}
                            </div>
                          </td>
                          <td className="scores-td pts-td">{hole[golfer.id].pts}</td>
                          <td className="scores-td three-ball-pts-td">{hole[golfer.id].threeBallPts}</td>
                        </tr>
                      : null
                )}
              </tbody>
            </table>
          </td>
        </tr>
      ) : null
    );

  render() {
    return (
      <div className="ThreeBallSixesSummary">
        <Breadcrumb>
          <Breadcrumb.Item onClick={this.props.homeClick}>
            {this.props.homeName}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="golfer-breadcrumb">
            Three Ball Sixes Summary
          </Breadcrumb.Item>
        </Breadcrumb>
        <form>
          <Grid className="holes-container">
            <Row className="show-grid">
              <Col md={6}>{this.renderHoleTable("front")}</Col>
              <Col md={6}>{this.renderHoleTable("back")}</Col>
            </Row>
          </Grid>
        </form>
      </div>
    );
  }
}

export default ThreeBallSixesSummary;
