import React from "react";
import { Table } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Driver from "components/svg/Driver";
import "./AggregatedRoundStats.css";

const distanceAverageMainTD = (props, desc, type) => {
  const d = props.data;
  return (
    <th>
      <span className="distance-avg-heading">{desc}</span>
      <span
        className={"distance-avg-val distance-range-" + d[type + "ToParRange"]}
      >
        {d[type]}
      </span>{" "}
      /{" "}
      <span
        className={"distance-avg-val distance-range-" + d[type + "PtsRange"]}
      >
        {d[type + "Pts"]} Pts
      </span>
    </th>
  );
};

const distanceAverageTD = (props, desc, type) => {
  const d = props.data.distanceAverages;
  return (
    <td>
      <span className="distance-avg-desc">{desc}</span>
      <span
        className={"distance-avg-val distance-range-" + d[type + "sToParRange"]}
      >
        {d[type + "s"]}
      </span>{" "}
      /{" "}
      <span
        className={"distance-avg-val distance-range-" + d[type + "spRange"]}
      >
        {d[type + "sp"]} Pts
      </span>
    </td>
  );
};

export default props => (
  <div className="AggregatedRoundStats">
    <h4>{props.heading ? props.heading : "My Round Stats"} </h4>
    <Table striped bordered condensed className="basic-stats">
      <tbody>
        <tr>
          <td>Number of Rounds</td>
          <td>
            {props.data.noOfRounds} -{" "}
            <Link to={"/golfer/" + props.golferId + "/all-rounds"}>
              Show All
            </Link>
          </td>
        </tr>
        <tr>
          <td>Stableford Points Avg.</td>
          <td>{props.data.sScore}</td>
        </tr>
        {props.data.bestRound ? (
          <tr>
            <td>Best Round</td>
            <td>
              <Link
                to={
                  "/round/" +
                  props.data.bestRound.roundId +
                  "/scorecard/" +
                  props.golferId
                }
              >
                {props.data.bestRound.score +
                  " / " +
                  props.data.bestRound.stableford +
                  " Pts" +
                  " - " +
                  props.data.bestRound.courseName}
              </Link>
            </td>
          </tr>
        ) : null}
      </tbody>
    </Table>
    <hr />
    <h4>Hole Averages</h4>
    <Row className="golf-balls">
      <Col className="hole-average-col" xs={4}>
        <div className="circle circle--a">
          <div className="circle-label">Par 3</div>
          <div className="circle-data">
            {props.data.avPar3} / {props.data.avPar3Pts} Pts
          </div>
        </div>
      </Col>
      <Col className="hole-average-col" xs={4}>
        <div className="circle circle--b">
          <div className="circle-label">Par 4</div>
          <div className="circle-data">
            {props.data.avPar4} / {props.data.avPar4Pts} Pts
          </div>
        </div>
      </Col>
      <Col className="hole-average-col" xs={4}>
        <div className="circle circle--c">
          <div className="circle-label">Par 5</div>
          <div className="circle-data">
            {props.data.avPar5} / {props.data.avPar5Pts} Pts
          </div>
        </div>
      </Col>
    </Row>
    {props.data.distanceAverages ? (
      <Table striped bordered condensed className="extra-stats distance-table">
        <thead>
          <tr>
            {distanceAverageMainTD(props, "Par 3 Averages", "avPar3")}
            {distanceAverageMainTD(props, "Par 4 Averages", "avPar4")}
            {distanceAverageMainTD(props, "Par 5 Averages", "avPar5")}
          </tr>
        </thead>
        <tbody>
          <tr>
            {distanceAverageTD(props, "< 135 Yards", "shortPar3")}
            {distanceAverageTD(props, "< 340 Yards", "shortPar4")}
            {distanceAverageTD(props, "< 460 Yards", "shortPar5")}
          </tr>
          <tr>
            {distanceAverageTD(props, ">= 135 & < 180 Yards", "mediumPar3")}
            {distanceAverageTD(props, ">= 340 & < 380 Yards", "mediumPar4")}
            {distanceAverageTD(props, ">= 460 & < 510 Yards", "mediumPar5")}
          </tr>
          <tr>
            {distanceAverageTD(props, ">= 180 Yards", "longPar3")}
            {distanceAverageTD(props, ">= 380 Yards", "longPar4")}
            {distanceAverageTD(props, ">= 510 Yards", "longPar5")}
          </tr>
        </tbody>
      </Table>
    ) : null}
    <hr />
    <h4>Round Breakdown</h4>
    <Table striped bordered condensed className="basic-stats">
      <tbody>
        <tr>
          <td>Front Nine Points Avg.</td>
          <td
            className={
              props.data.frontNineSScore < props.data.backNineSScore
                ? "less-than-avg"
                : props.data.frontNineSScore > props.data.backNineSScore
                ? "more-than-avg"
                : ""
            }
          >
            {props.data.frontNineSScore}
          </td>
        </tr>
        <tr>
          <td>Back Nine Points Avg.</td>
          <td
            className={
              props.data.frontNineSScore > props.data.backNineSScore
                ? "less-than-avg"
                : props.data.frontNineSScore < props.data.backNineSScore
                ? "more-than-avg"
                : ""
            }
          >
            {props.data.backNineSScore}
          </td>
        </tr>
        <tr>
          <td>Net Birdies</td>
          <td>{props.data.birdieCount}</td>
        </tr>
        <tr>
          <td>Net Eagles</td>
          <td>{props.data.eagleCount}</td>
        </tr>
        {props.data.albatrossCount ? (
          <tr>
            <td>Net Albatrosses</td>
            <td>{props.data.albatrossCount}</td>
          </tr>
        ) : null}
      </tbody>
    </Table>
    {props.data.courses && props.data.courses.length > 0 ? (
      <div>
        <hr />
        <h4>Courses</h4>
        <Table striped bordered condensed className="basic-stats">
          <thead>
            <tr>
              <th className="course-col">Course</th>
              <th className="avg-pts-col">Points Avg.</th>
            </tr>
          </thead>
          <tbody>
            {[{}].concat(props.data.courses).map((item, idx) =>
              idx !== 0 ? (
                <tr key={idx}>
                  <td>{item.courseName}</td>
                  <td
                    className={
                      item.avgPts < props.data.sScore
                        ? "less-than-avg"
                        : item.avgPts > props.data.sScore
                        ? "more-than-avg"
                        : ""
                    }
                  >
                    {item.avgPts}
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </Table>
      </div>
    ) : null}
    <hr />
    <h4>Nearest &amp; Longest</h4>
    <Table striped bordered condensed className="basic-stats">
      <tbody>
        <tr>
          <td>
            <span className="fas fa-thumbtack" />
            Nearest the Pins
          </td>
          <td>{props.data.nrstPin}</td>
        </tr>
        <tr>
          <td>
            <span className="fas fa-bullseye" />
            Nearest the Pin in Twos
          </td>
          <td>{props.data.nrstPinIn2}</td>
        </tr>
        <tr>
          <td>
            <Driver />
            Longest Drives
          </td>
          <td>{props.data.lgestDr}</td>
        </tr>
      </tbody>
    </Table>
  </div>
);
