import React from "react";
import { Table } from "react-bootstrap";
import "./AnnualGolferHandicapChange.css";

export default props => (
  <div className="AnnualGolferHandicapChange">
    <div className="large-screen hidden-md hidden-sm hidden-xs">
      <Table striped bordered condensed>
        <thead>
          <tr>
            <th className="round-col">
              {props.heading ? props.heading : "Round"}
            </th>
            <th className="score-col">Score</th>
            <th className="handicap-col">
              <span>Handicap</span>
            </th>
            <th className="adj-col">
              <span>Adjustment</span>
            </th>
            <th className="win-loss-col">
              <span>First / Last Adjustment</span>
            </th>
            <th className="new-handicap-col">
              <span>New Handicap</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {[{}].concat(props.data).map(
            (item, idx) =>
              idx !== 0 && item["courseName"] ? (
                <tr key={idx}>
                  <td>
                    {props.renderName ? (
                      props.renderName(item)
                    ) : (
                      <span>
                        <div className="round-date">{item.courseName}</div>
                        {item.roundDate}
                      </span>
                    )}
                  </td>
                  <td>{item.stablefordPoints}</td>
                  <td>
                    {item.handicap}
                    {item.manualAdjustment ? (
                      <span className="manual-adjustment">
                        {" "}
                        ({item.manualAdjustment})
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{item.handicapChange}</td>
                  <td>{item.handicapChangeWinLast}</td>
                  <td>
                    <span className="new-handicap">{item.newHandicap}</span>{" "}
                    <span
                      className={
                        item.newHandicap > item.handicap
                          ? "fas fa-arrow-up"
                          : item.newHandicap < item.handicap
                            ? "fas fa-arrow-down"
                            : "fas fa-arrow-right"
                      }
                    />
                  </td>
                </tr>
              ) : null
          )}
        </tbody>
      </Table>
    </div>
    <div className="small-screen hidden-lg">
      {[{}].concat(props.data).map(
        (item, idx) =>
          idx !== 0 && item["courseName"] ? (
            <div key={idx}>
              <h4>
                {props.renderName
                  ? props.renderName(item)
                  : item.courseName + " - " + item.roundDate}
              </h4>
              <Table striped bordered condensed>
                <thead>
                  <tr>
                    <th className="score-col">Score</th>
                    <th className="handicap-col">
                      <span className="hidden-md">H-cap</span>
                      <span className="hidden-sm hidden-xs">Handicap</span>
                    </th>
                    <th className="adj-col">
                      <span>Adj.</span>
                    </th>
                    <th className="win-loss-col">
                      <span>First / Last Adj.</span>
                    </th>
                    <th className="new-handicap-col">
                      <span className="hidden-md">New H-cap</span>
                      <span className="hidden-sm hidden-xs">New Handicap</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.stablefordPoints}</td>
                    <td>
                      {item.handicap}
                      {item.manualAdjustment ? (
                        <span className="manual-adjustment">
                          {" "}
                          ({item.manualAdjustment})
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{item.handicapChange}</td>
                    <td>{item.handicapChangeWinLast}</td>
                    <td>
                      <span className="new-handicap">{item.newHandicap}</span>{" "}
                      <span
                        className={
                          item.newHandicap > item.handicap
                            ? "fas fa-arrow-up"
                            : item.newHandicap < item.handicap
                              ? "fas fa-arrow-down"
                              : "fas fa-arrow-right"
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          ) : null
      )}
    </div>
  </div>
);
