import React from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label
} from "recharts";
import { Link } from "react-router-dom";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import "./HandicapChart.css";

const widthBreak = 430;

const formatter = (value, name, props) => {
  return value;
};
const labelFormatter = (value, name, props) => {
  return name;
};
const tickFormatter = value => {
  return parseFloat(Number(value).toFixed(2));
};
const getMargin = () => {
  let margin = { top: 5, right: 0, left: 0, bottom: 5 };
  if (window.outerWidth < widthBreak) {
    margin = { top: 5, right: -25, left: -26, bottom: 5 };
  }
  return margin;
};

const CustomTooltip = props => { 
  // payload[0] doesn't exist when tooltip isn't visible
  if (props.payload[0] != null) {
    // mutating props directly is against react's conventions
    // so we create a new payload with the name and value fields set to what we want
    const newPayload = [
      ...props.payload,
      {
        name: "Course",
        // all your data which created the tooltip is located in the .payload property
        value: props.payload[0].payload.courseName
        // you can also add "unit" here if you need it
      }
    ];

    // we render the default, but with our overridden payload
    return <DefaultTooltipContent payload={newPayload} />;
  }

  // we just render the default
  return <DefaultTooltipContent {...props} />;
};

export default props => (
  <div className="HandicapChart">
    {props.noHeading ? null : (
      <h4>{props.heading ? props.heading : "My Handicap"}</h4>
    )}
    <ComposedChart
      width={props.width}
      height={300}
      data={props.data}
      margin={getMargin()}
    >
      <XAxis
        tickFormatter={val => ""}
        tickLine={false}
        axisLine={false}
        dataKey="roundDate"
      >
        <Label position="center" style={{ textAnchor: "middle" }} offset={-10}>
          {props.startYear + "-" + (props.startYear + 5) + " Rounds"}
        </Label>
      </XAxis>
      <YAxis
        yAxisId="1"
        orientation="right"
        tickFormatter={tickFormatter}
        tickLine={false}
        domain={["dataMin - 1", "dataMax + 1"]}
      >
        {window.outerWidth >= widthBreak ? (
          <Label
            angle={90}
            position="right"
            offset={-10}
            style={{ textAnchor: "middle" }}
          >
            Handicap
          </Label>
        ) : null}
      </YAxis>
      <YAxis
        yAxisId="2"
        tickLine={false}
        domain={["dataMin - 1", "dataMax + 1"]}
      >
        {window.outerWidth >= widthBreak ? (
          <Label
            angle={270}
            position="left"
            offset={-10}
            style={{ textAnchor: "middle" }}
          >
            Stableford Pts.
          </Label>
        ) : null}
      </YAxis>
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip
        formatter={formatter}
        labelFormatter={labelFormatter}
        content={<CustomTooltip />}
      />
      <Legend verticalAlign="top" align="center" height={46} />
      <Bar
        name="Stableford Pts."
        yAxisId="2"
        dataKey="stablefordPoints"
        barSize={8}
        fill="#4267b2"
      />
      <Bar name="CSS" yAxisId="2" dataKey="css" barSize={8} fill="#fcb400" />
      <Line
        name="Handicap"
        yAxisId="1"
        type="monotone"
        dataKey="handicap"
        stroke="#06af8e"
        activeDot={{ r: 8 }}
      />
    </ComposedChart>
    {props.showAllLink ? (
      <div className="show-all-link">
        <Link to={"/handicaps/" + props.golferId}>Show Breakdown</Link>
      </div>
    ) : null}
  </div>
);
