import React, { Component } from "react";
import * as API from "API";
import { withRouter } from "react-router-dom";
import Photos from "components/photos/Photos";

class BBGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      compName: "",
      ready: false
    };
  }
  componentDidMount = async () => {
    if (this.props.match.params.id) {
      this.props.setLoading(true);
      const comp = await API.getCompetition(this.props.match.params.id);
      this.setState({
        competitionId: this.props.match.params.id,
        compName: comp.compName,
        ready: true
      });
    } else {
      this.setState({ ready: true });
    }
    this.props.setLoading(false);
  };

  render = () => (
    <div className="Gallery">
      {this.state.ready ? (
        <Photos
          competitionId={this.props.match.params.id}
          showUpload={
            this.props.currentUser && this.props.currentUser.memberStatus
              ? true
              : false
          }
          folder={this.props.match.params.id ? "competition" : "gallery"}
          compName={this.state.compName}
        />
      ) : null}
    </div>
  );
}

export default withRouter(BBGallery);
