import React from "react";

export default props => (
  <svg
    style={{
      width: "16px",
      fill: "#06af8e",
      marginRight: "4px"
    }}
    viewBox="0 0 135 110"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <path
      stroke="rgb(255,0,0)"
      d="M 61.116 35.801 C 79.106 35.522 86.549 36.647 86.593 36.64 C 86.62 36.576 122.487 40.916 126.298 46.611 C 131.148 51.086 129.982 59.778 129.168 71.167 C 127.969 83.194 117.801 91.11 117.791 91.204 C 103.934 103.407 92.426 101.897 60.439 101.337 C 45.112 101.069 32.518 100.393 24.204 91.038 C 19.007 84.123 15.589 76.801 15.243 64.598 C 14.896 54.023 16.39 46.905 21.586 43.245 C 31.287 37.143 40.288 36.124 61.116 35.801 Z M 3.083 3.974 L 22.38 0.874 L 42.916 39.539 L 22.426 45.559 L 3.083 3.974 Z"
      style={{
        stroke: "rgb(129, 151, 57)",
        strokeWidth: "0px"
      }}
    />
    <rect
      style={{
        fill: "rgb(255, 255, 255)",
        stroke: "rgb(251, 252, 248)",
        strokeWidth: "6px"
      }}
      x="32.382"
      y="57.771"
      width="80"
      height="2.693"
      rx="0"
      ry="0"
    />
    <rect
      style={{
        fill: "rgb(255, 255, 255)",
        stroke: "rgb(251, 252, 248)",
        strokeWidth: "6px"
      }}
      x="32.4"
      y="77.304"
      width="80"
      height="2.693"
    />
  </svg>
);
