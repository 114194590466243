import React from "react";
import { Glyphicon } from "react-bootstrap";
import "./PasswordValidation.css";

const PasswordValidationRow = props => (
  <tr className="password-rule">
    <td>
      {!props.test ? (
        <Glyphicon glyph="exclamation-sign" className="text-danger" />
      ) : (
        <Glyphicon glyph="ok-circle" className="text-success" />
      )}
    </td>
    <td>
      <span className="password-rule-text"> {props.message} </span>
    </td>
  </tr>
);

export default ({
  passwordFocused,
  passwordHasUpper,
  passwordHasNumber,
  passwordHasLength = "",
  ...props
}) => (
  <div
    className={
      !passwordFocused ? "password-validation hide" : "password-validation show"
    }
  >
    <div className="password-validation-title">Password Rules</div>
    <table>
      <tbody>
        <PasswordValidationRow
          test={passwordHasLength}
          message="8 characters minimum"
        />
        <PasswordValidationRow
          test={passwordHasUpper}
          message="Contains at least 1 capital letter"
        />
        <PasswordValidationRow
          test={passwordHasNumber}
          message="Contains at least 1 number"
        />
      </tbody>
    </table>
  </div>
);
