import React, { Component } from "react";
import * as API from "API";
import PageHeader from "components/page-header/PageHeader";
import EntityList from "components/entity-list/EntityList";
import { COURSES_CONTENT as PAGE_CONTENT } from "libs/content";

export default class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      courses: []
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const results = await API.coursesLite();
      this.props.setLoading(false);
      this.setState({ courses: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  displayHeader = course => {
    return course.courseName;
  };

  render = () => (
    <div className="Courses">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <EntityList
        {...this.props}
        isLoading={this.state.isLoading}
        listItems={this.state.courses}
        type="course"
        header={this.displayHeader}
      />
    </div>
  );
}
