import React from "react";
import { Alert, Table, Button } from "react-bootstrap";
import FormRow from "components/form-row/FormRow";
import "./FinalScores.css";

const getRowId = (groupIdx, golferIdx) =>
  "group" + groupIdx + "golfer" + golferIdx;

export default props => (
  <div className="FinalScores">
    {props.completeScores ? null : (
      <Alert bsStyle="warning">
        <h4>Warning</h4>
        <p>
          Please note that not recording hole by hole scores will mean that some
          competitions (matchplay, par three etc) cannot be calculated.
        </p>
      </Alert>
    )}
    <Table striped bordered condensed className="final-scores-table">
      <thead>
        <tr>
          <th className="stableford-points-name-col">Golfer</th>
          <th className="stableford-points-col">Handicap</th>
          <th className="stableford-points-col">
            {props.completeScores ? "Scores" : "Stableford Points"}
          </th>
        </tr>
      </thead>
      <tbody>
        {[{}].concat(props.groups).map(
          (group, groupIdx) =>
            groupIdx !== 0
              ? [{}].concat(group.golfers).map(
                  (golfer, golferIdx) =>
                    golferIdx !== 0 ? (
                      <tr key={groupIdx + "-" + golferIdx}>
                        <td>{golfer.givenName + " " + golfer.familyName}</td>
                        <td>
                          <FormRow
                            type="number"
                            controlId={getRowId(groupIdx, golferIdx) + "hcp"}
                            errors={props.errors}
                            value={golfer.handicap ? golfer.handicap : ""}
                            onChange={e => {
                              props.onChange(
                                group,
                                golfer,
                                e.target.value,
                                true
                              );
                            }}
                            onFocus={props.onFocus}
                            onBlur={e =>
                              props.onBlur(
                                getRowId(groupIdx, golferIdx) + "hcp",
                                golfer,
                                true
                              )
                            }
                            validationMessage="Please enter a valid handicap."
                            min={0}
                            max={60}
                            step="any"
                          />
                        </td>
                        <td className="scores">
                          {props.completeScores ? (
                            <div>
                              <Button
                                block
                                bsSize="small"
                                bsStyle={
                                  golfer.stablefordPoints
                                    ? "default"
                                    : "primary"
                                }
                                disabled={!golfer.handicap}
                                onClick={e => {
                                  props.editGolferScores(golfer);
                                }}
                              >
                                {golfer.stablefordPoints
                                  ? golfer.stablefordPoints + "pts - "
                                  : null}
                                Edit
                              </Button>
                            </div>
                          ) : (
                            <FormRow
                              type="number"
                              controlId={getRowId(groupIdx, golferIdx)}
                              errors={props.errors}
                              value={
                                golfer.stablefordPoints
                                  ? golfer.stablefordPoints
                                  : ""
                              }
                              onChange={e => {
                                props.onChange(
                                  group,
                                  golfer,
                                  e.target.value,
                                  false
                                );
                              }}
                              onFocus={props.onFocus}
                              onBlur={e =>
                                props.onBlur(
                                  getRowId(groupIdx, golferIdx),
                                  golfer,
                                  false
                                )
                              }
                              validationMessage="Please enter a valid score."
                              min={0}
                              max={60}
                              step="any"
                            />
                          )}
                        </td>
                      </tr>
                    ) : null
                )
              : null
        )}
      </tbody>
    </Table>
  </div>
);
