import React, { Component } from "react";
import * as API from "API";
import { Breadcrumb } from "react-bootstrap";
import ScoresByRound from "components/scores-by-round/ScoresByRound";
import ScoresByRoundDetail from "components/scores-by-round-detail/ScoresByRoundDetail";
import TeamScoresByRound from "components/team-scores-by-round/TeamScoresByRound";
import { withRouter } from "react-router-dom";
import { getOrdinalSuffix } from "libs/formUtils.js";
import "./CompetitionSummaryByType.css";

class CompetitionSummaryByType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compName: "",
      compType: "",
      dateFrom: "",
      dateTo: "",
      teams: [],
      rounds: [],
      individualComp: "individualStableford",
      ryderCup: false,
      excludeRyderCupScoreFromIndividual: false,
      formattedData: {}
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const scoresPromise = API.getScores(
        this.props.match.params.id,
        this.props.match.params.round
      );

      const scores = await scoresPromise;
      const comp = scores.competition,
        rounds = scores.rounds;

      this.props.setLoading(false);
      const competition = {
        id: comp.id || "",
        golferId: this.props.match.params.golferId,
        type: this.props.match.params.type,
        round: this.props.match.params.round,
        compName: comp.compName || "",
        compType: comp.compType || "",
        dateFrom: comp.dateFrom || "",
        dateTo: comp.dateTo || "",
        teams: comp.teams || [],
        rounds: rounds || [],
        individualComp: comp.individualComp || "individualStableford",
        ryderCup: comp.ryderCup || false,
        excludeRyderCupScoreFromIndividual:
          comp.excludeRyderCupScoreFromIndividual || false,
        formattedData: scores.formattedData
      };
      this.setState(competition);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  renderTeamName = team => team.name;

  getIndividualData = golfer => {
    let arr = [],
      roundNumber = 1;

    while (typeof golfer["round_points" + roundNumber] !== "undefined") {
      let front = golfer["ryderCupFront" + roundNumber],
        back = golfer["ryderCupBack" + roundNumber],
        overall = golfer["ryderCupOverall" + roundNumber],
        place = golfer["ryderCupGroupPlace" + roundNumber],
        position = golfer["ryderCupGroupPosition" + roundNumber],
        groupStableford = golfer["ryderCupGroupTotalStableford" + roundNumber],
        individualPlace = golfer["round_points" + roundNumber],
        individualPosition = golfer["round_position" + roundNumber],
        individualStableford = golfer["round_stableford" + roundNumber],
        longestDrive = golfer["longestDrive_round" + roundNumber] ? 1 : 0,
        nearestPin = golfer["nearestPin_round" + roundNumber] ? 1 : 0,
        nearestPinIn2 = golfer["nearestPinIn2_round" + roundNumber] ? 1 : 0,
        ryderCup = golfer["ryderCup" + roundNumber]
          ? golfer["ryderCup" + roundNumber]
          : 0,
        roundTotal =
          (!this.state.excludeRyderCupScoreFromIndividual ? ryderCup : 0) +
          longestDrive +
          nearestPin +
          nearestPinIn2 +
          individualPlace;
      if (!this.state.round) {
        arr.push({ label: "Round " + roundNumber, value: "" });
      }
      if (
        this.state.ryderCup &&
        !this.state.excludeRyderCupScoreFromIndividual
      ) {
        arr.push({
          label: "Front",
          value: front
        });
        arr.push({ label: "Back", value: back });
        arr.push({
          label: "Overall",
          value: overall
        });
        if (place && position) {
          arr.push({
            label: "Team Position",
            value:
              place +
              " (" +
              getOrdinalSuffix(position) +
              " - " +
              groupStableford +
              "pts)"
          });
        }
      }
      arr.push({
        label: "Individual Position",
        value:
          individualPlace +
          " (" +
          getOrdinalSuffix(individualPosition) +
          " - " +
          individualStableford +
          "pts)"
      });
      if (nearestPin) {
        arr.push({
          label: "Nearest The Pin",
          value: nearestPin
        });
      }
      if (nearestPinIn2) {
        arr.push({
          label: "Nearest The Pin In 2",
          value: nearestPinIn2
        });
      }
      if (longestDrive) {
        arr.push({
          label: "Longest Drive",
          value: longestDrive
        });
      }
      arr.push({
        label: "Total",
        value: roundTotal
      });
      roundNumber = roundNumber + 1;
    }
    return arr;
  };

  getRyderCupIndividualData = golfer => {
    let arr = [],
      roundNumber = 1;
    while (typeof golfer["ryderCup" + roundNumber] !== "undefined") {
      let front = golfer["ryderCupFront" + roundNumber],
        back = golfer["ryderCupBack" + roundNumber],
        overall = golfer["ryderCupOverall" + roundNumber],
        place = golfer["ryderCupGroupPlace" + roundNumber],
        position = golfer["ryderCupGroupPosition" + roundNumber],
        groupStableford = golfer["ryderCupGroupTotalStableford" + roundNumber],
        longestDrive = golfer["longestDrive_round" + roundNumber] ? 1 : 0,
        nearestPin = golfer["nearestPin_round" + roundNumber] ? 1 : 0,
        nearestPinIn2 = golfer["nearestPinIn2_round" + roundNumber] ? 1 : 0,
        ryderCup = golfer["ryderCup" + roundNumber],
        roundTotal = ryderCup + longestDrive + nearestPin + nearestPinIn2;
      if (!this.state.round) {
        arr.push({ label: "Round " + roundNumber, value: "" });
      }
      arr.push({
        label: "Front",
        value: front
      });
      arr.push({ label: "Back", value: back });
      arr.push({
        label: "Overall",
        value: overall
      });
      if (place && position) {
        arr.push({
          label: "Team Position",
          value:
            place +
            " (" +
            getOrdinalSuffix(position) +
            " - " +
            groupStableford +
            "pts)"
        });
      }
      if (nearestPin) {
        arr.push({
          label: "Nearest The Pin",
          value: nearestPin
        });
      }
      if (nearestPinIn2) {
        arr.push({
          label: "Nearest The Pin In 2",
          value: nearestPinIn2
        });
      }
      if (longestDrive) {
        arr.push({
          label: "Longest Drive",
          value: longestDrive
        });
      }
      arr.push({
        label: "Total",
        value: roundTotal
      });
      roundNumber = roundNumber + 1;
    }
    return arr;
  };

  getTeamRoundsArray = (teamA, teamB, field) => {
    let arr = [],
      roundNumber = 1;
    while (teamA[field + roundNumber]) {
      arr.push({
        round: "R" + roundNumber,
        a: teamA["ryderCup" + roundNumber],
        b: teamB["ryderCup" + roundNumber]
      });
      roundNumber = roundNumber + 1;
    }
    arr.push({ round: "NP", a: teamA.nearestPin, b: teamB.nearestPin });
    arr.push({ round: "NP2", a: teamA.nearestPinIn2, b: teamB.nearestPinIn2 });
    arr.push({ round: "LD", a: teamA.longestDrive, b: teamB.longestDrive });
    return arr;
  };

  getRoundsArray = (golfer, field, lowRound) => {
    let arr = [],
      roundNumber = 1,
      lowRoundSet = false;
    lowRound = lowRound ? golfer[lowRound] : null;
    while (golfer[field + roundNumber]) {
      let itm = { nbr: roundNumber, score: golfer[field + roundNumber] };
      if (lowRound && !lowRoundSet && lowRound === itm.score) {
        itm.lowRound = true;
        lowRoundSet = true;
      }
      arr.push(itm);
      roundNumber = roundNumber + 1;
    }
    return arr;
  };

  renderIndividualPoints = () => (
    <div>
      <h3>Individual Competition</h3>
      <h4>Points System</h4>
      <ScoresByRoundDetail
        data={
          this.state.formattedData[
            this.state.ryderCup &&
            !this.state.excludeRyderCupScoreFromIndividual
              ? "pointsSystemIncRyderCupData"
              : "pointsSystemData"
          ]
        }
        roundBreakdown={this.getIndividualData}
        totalField={
          !this.state.round
            ? this.state.ryderCup &&
              !this.state.excludeRyderCupScoreFromIndividual
              ? "totalPointsSystemScoreIncRyderCup"
              : "totalPointsSystemScore"
            : null
        }
        field="round_points"
        rounds={this.getRoundsArray}
        golferId={this.state.golferId}
      />
    </div>
  );

  renderStableford = () => (
    <div>
      <h3>Individual Competition</h3>
      <h4>Stableford</h4>
      <ScoresByRound
        data={this.state.formattedData.stablefordData}
        field="round_stableford"
        totalField="stablefordScore"
        rounds={this.getRoundsArray}
        golferId={this.state.golferId}
      />
    </div>
  );

  renderDropLowRound = () => (
    <div>
      <h3>Individual Competition</h3>
      <h4>Stableford (Drop Low Round)</h4>
      <ScoresByRound
        data={this.state.formattedData.dropLowRoundData}
        field="round_stableford"
        totalField="dropLowRoundScore"
        lowRound="droppedRoundScore"
        rounds={this.getRoundsArray}
        golferId={this.state.golferId}
      />
    </div>
  );

  renderParThreeChamp = () =>
    this.state.formattedData.parThreeData ? (
      <div>
        <h3>Par Three Champ</h3>
        <ScoresByRound
          data={this.state.formattedData.parThreeData}
          field="round_parThree"
          totalField="parThreeScore"
          rounds={this.getRoundsArray}
          golferId={this.state.golferId}
        />
      </div>
    ) : null;

  renderRyderCup = () =>
    this.state.ryderCup ? (
      <div>
        <h3>Ryder Cup</h3>
        <TeamScoresByRound
          data={this.state.formattedData.ryderCupData}
          field="ryderCup"
          totalField="ryderCupTotal"
          rounds={this.getTeamRoundsArray}
        />
      </div>
    ) : null;

  renderRyderCupIndividual = () =>
    this.state.ryderCup ? (
      <div>
        <h3>Ryder Cup Individual</h3>
        <ScoresByRoundDetail
          data={this.state.formattedData.ryderCupIndividualData}
          roundBreakdown={this.getRyderCupIndividualData}
          totalField={!this.state.round ? "ryderCupTotal" : null}
          field="ryderCup"
          rounds={this.getRoundsArray}
          golferId={this.state.golferId}
        />
      </div>
    ) : null;

  render = () => (
    <div className="CompetitionSummaryByType">
      <Breadcrumb>
        <Breadcrumb.Item onClick={e => this.props.history.goBack()}>
          {!this.state.round
            ? this.state.compName
            : this.state.rounds.length === 1 && this.state.rounds[0].course
            ? this.state.rounds[0].course.courseName +
              " - " +
              this.state.rounds[0].roundDate
            : null}
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="golfer-breadcrumb">
          {this.state.type === "individual-points"
            ? "Individual Points"
            : this.state.type === "drop-low-round"
            ? "Stableford (Drop Low Round)"
            : this.state.type === "stableford"
            ? "Stableford"
            : this.state.type === "par-three-champ"
            ? "Par Three Champ"
            : this.state.type === "ryder-cup"
            ? "Ryder Cup"
            : this.state.type === "ryder-cup-individual"
            ? "Ryder Cup Individual"
            : null}
        </Breadcrumb.Item>
      </Breadcrumb>
      <form>
        {!this.state.round ? (
          <div>
            <h2>{this.state.compName}</h2>
            <div className="text-muted">
              Results taken from {this.state.rounds.length} rounds
            </div>
          </div>
        ) : this.state.rounds.length === 1 && this.state.rounds[0].course ? (
          <h2>
            {this.state.rounds[0].course.courseName +
              " - " +
              this.state.rounds[0].roundDate}
          </h2>
        ) : null}
        {this.state.type === "individual-points"
          ? this.renderIndividualPoints()
          : this.state.type === "drop-low-round"
          ? this.renderDropLowRound()
          : this.state.type === "stableford"
          ? this.renderStableford()
          : this.state.type === "par-three-champ"
          ? this.renderParThreeChamp()
          : this.state.type === "ryder-cup"
          ? this.renderRyderCup()
          : this.state.type === "ryder-cup-individual"
          ? this.renderRyderCupIndividual()
          : null}
      </form>
    </div>
  );
}

export default withRouter(CompetitionSummaryByType);
