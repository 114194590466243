import React from "react";
import { Glyphicon } from "react-bootstrap";
import "./LoaderLink.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) => (
  <div className="LoaderLinkWrapper">
    {isLoading ? (
      <span className={`LoaderLink ${className} `}>
        <Glyphicon glyph="refresh" className="spinning" /> {loadingText}
      </span>
    ) : (
      <a className={`LoaderLink ${className} `} {...props}>
        {text}
      </a>
    )}
  </div>
);
