import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authentication from "./authentication";
import utility from "./utility";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  authentication: authentication,
  utility: utility
})
export default createRootReducer
