import React from "react";
import { Glyphicon } from "react-bootstrap";
import "./FileUpload.css";

export default props => (
  <div className="FileInput form-group form-group-lg">
    {props.label ? (
      <label for={props.controlId} className="control-label">
        {props.label}
      </label>
    ) : null}
    <div
      className={
        (props.isLoading ? "loading-file-upload" : "") + " file-upload-wrapper"
      }
    >
      <button
        className="btn btn-lg btn-primary"
        type="button"
        disabled={props.disabled || props.isLoading}
      >
        {props.isLoading && <Glyphicon glyph="refresh" className="spinning" />}
        {!props.isLoading && <Glyphicon glyph="upload" />}
        {!props.isLoading ? props.uploadBtnText : props.loadingText}
      </button>
      <input
        type="file"
        id={props.controlId}
        multiple={props.multiple}
        accept={props.accept}
        capture={props.capture}
        onChange={props.handleFileChange}
      />
    </div>
  </div>
);
