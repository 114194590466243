import React from "react";
import {
  Button,
  Breadcrumb,
  ListGroup,
  ListGroupItem,
  Glyphicon
} from "react-bootstrap";
import FormAlert from "components/form-alert/FormAlert";
import LoaderLink from "components/loader-link/LoaderLink";
import "./SelectGolfers.css";

const competitionGolfers = (competition, golfers, incl) => {
  let filtered = [],
    inComp = false;
  for (let golfer of golfers) {
    inComp = false;
    for (let t of competition.teams) {
      for (let g of t.golfers) {
        if (golfer.id === g.id) {
          inComp = true;
        }
      }
    }
    if (inComp && incl) {
      filtered.push(golfer);
    } else if (!inComp && !incl) {
      filtered.push(golfer);
    }
  }
  return filtered;
};

const renderList = (props, golfers) => {
  return [{}].concat(golfers).map((golfer, index) =>
    index !== 0 ? (
      <ListGroupItem key={index} onClick={e => props.toggleGolfer(golfer)}>
        {props.isGolferSelected(golfer) ? (
          <Glyphicon glyph="ok" className="golfer-selected pull-right" />
        ) : null}
        <h4>
          {golfer.givenName + " " + golfer.familyName + " - " + golfer.handicap}
        </h4>
        {props.alreadyIn(golfer.id) ? (
          <div className="already-in">{props.alreadyIn(golfer.id)}</div>
        ) : null}
      </ListGroupItem>
    ) : null
  );
};

export default props => (
  <div className="SelectGolfers">
    <Breadcrumb>
      <Breadcrumb.Item onClick={props.homeClick}>
        {props.homeName}
      </Breadcrumb.Item>
      <Breadcrumb.Item active className="team-breadcrumb">
        {props.name}
      </Breadcrumb.Item>
    </Breadcrumb>
    <form>
      <FormAlert formError={props.formError} />
      {props.children}
      {props.competition ? (
        <div>
          <h3>Playing in Competition</h3>
          <ListGroup>
            {renderList(
              props,
              competitionGolfers(props.competition, props.golfers, true)
            )}
          </ListGroup>
          <Button
            className={"confirm-" + props.type}
            block
            bsSize="large"
            bsStyle="primary"
            onClick={props.confirm}
            disabled={props.confirmDisabled}
          >
            {"Confirm " + props.type}
          </Button>
          <hr />
          <h3>Not Playing in Competition</h3>
          <ListGroup>
            {renderList(
              props,
              competitionGolfers(props.competition, props.golfers, false)
            )}
          </ListGroup>
        </div>
      ) : (
        <ListGroup>{renderList(props, props.golfers)}</ListGroup>
      )}
      <Button
        className={"confirm-" + props.type}
        block
        bsSize="large"
        onClick={props.confirm}
        disabled={props.confirmDisabled}
      >
        {"Confirm " + props.type}
      </Button>
      {props.showDelete ? (
        <LoaderLink
          className={"remove-" + props.type}
          onClick={props.remove}
          text={"Delete " + props.type}
        />
      ) : null}
    </form>
  </div>
);
