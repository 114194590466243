import React, { Component } from "react";
import Gallery from "react-grid-gallery";
import { s3Upload, getS3URL } from "libs/awsLib";
import FileUpload from "components/file-upload/FileUpload";
import LoaderButton from "components/loader-button/LoaderButton";
import Thumbnail from "components/thumbnail/Thumbnail";
import spinnerImg from "img/Spinner-1s-200px.gif";
import * as API from "API";
import "./Photos.css";

class Photos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roundId: null,
      competitionId: null,
      images: [],
      processing: false,
      loading: false,
      uploading: false,
      deleting: false,
      galleryWidth: 300,
      resizeUpdater: this.updateGalleryWidth.bind(this)
    };
  }

  componentDidMount = async () => {
    this.refresh(this.props.preloadedImages);
    this.updateGalleryWidth();
    window.addEventListener("resize", this.state.resizeUpdater);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.state.resizeUpdater);
  };

  updateGalleryWidth = () => {
    const galleryWidth =
      document.getElementsByClassName("Photos").length > 0
        ? document.getElementsByClassName("Photos")[0].offsetWidth
        : 0;
    this.setState({
      galleryWidth: galleryWidth
    });
  };

  refresh = async preloadedImages => {
    if (
      (this.props.folder === "scorecards" &&
        this.props.roundId === this.state.roundId) ||
      (this.props.folder === "competition" &&
        this.props.competitionId === this.state.competitionId)
    ) {
      return;
    }
    let updatedState = {
      roundId: this.props.roundId,
      competitionId: this.props.competitionId
    };

    if (preloadedImages) {
      updatedState.images = this.formatImages(preloadedImages);
      this.setState(updatedState);
    } else {
      updatedState.loading = true;
      this.setState(updatedState);
      this.getImages();
    }
  };

  getImages = async omitStateUpdate => {
    const photos = await (this.props.folder === "scorecards"
      ? API.getScorecardPhotos(this.props.roundId)
      : this.props.folder === "competition"
      ? API.getCompetitionPhotos(this.props.competitionId)
      : API.getGalleryPhotos());

    let gallery = this.formatImages(photos);

    if (!omitStateUpdate && this.props.emptyCallback) {
      this.props.emptyCallback(gallery);
    }

    this.setState({
      loading: false
    });

    if (!omitStateUpdate && this.state.images.length !== gallery.length) {
      this.setState({
        images: gallery
      });
    }
    return gallery;
  };

  formatImages = photos => {
    let gallery = [];
    for (let image of photos) {
      image.srcId = image.src;
      image.src = spinnerImg;

      if (this.props.folder === "gallery" && image.compYear && image.compName) {
        image.tags = [
          {
            value: image.compYear + " - " + image.compType,
            title: image.compYear + " - " + image.compType
          }
        ];
        image.caption = image.compYear + " - " + image.compName;
      }
      gallery.push(image);
    }
    return gallery;
  };

  delete = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete the selected photos?"
    );
    if (!confirmed) {
      return;
    }

    this.setState({
      deleting: true
    });

    var forKeeps = [],
      forDelete = [];
    for (var i = 0; i < this.state.images.length; i++) {
      if (this.state.images[i].isSelected === true) {
        forDelete.push({
          pk: this.state.images[i].pk,
          sk: this.state.images[i].sk,
          galleryLink: this.state.images[i].galleryLink
        });
      } else {
        forKeeps.push(this.state.images[i]);
      }
    }

    await (this.props.folder === "scorecards"
      ? await API.deleteScorecardPhotos(this.props.roundId, {
          images: forDelete
        })
      : this.props.folder === "competition"
      ? API.deleteCompetitionPhotos(this.props.competitionId, {
          images: forDelete
        })
      : API.deleteGalleryPhotos({ images: forDelete }));

    this.setState({
      images: forKeeps,
      deleting: false
    });
    if (this.props.emptyCallback) {
      this.props.emptyCallback(forKeeps);
    }
    return;
  };

  containsSelections = () => {
    return this.state.images.filter(image => image.isSelected).length > 0;
  };

  onSelectImage = (index, image) => {
    var images = this.state.images.slice();
    var img = images[index];
    if (img.hasOwnProperty("isSelected")) img.isSelected = !img.isSelected;
    else img.isSelected = true;

    this.setState({
      images: images
    });
  };

  handleFileChange = async event => {
    if (event.target.files) {
      this.setState({
        uploading: true
      });

      let prefix = "originals/gallery/";

      if (this.props.folder === "scorecards") {
        prefix =
          "originals/" + this.props.folder + "/" + this.state.roundId + "/";
      } else if (this.props.folder === "competition") {
        prefix =
          "originals/gallery/" +
          this.props.folder +
          "/" +
          this.state.competitionId +
          "/";
      }

      let newFiles = await s3Upload(prefix, event.target.files);

      this.setState({
        uploading: false
      });

      this.delayedReload(
        this.state.images.length,
        newFiles.length > 20 ? 10000 : newFiles.length > 10 ? 7500 : 4000,
        newFiles.length + this.state.images.length,
        3
      );
    }
  };

  delayedReload = (oldCount, delay, count, terminateAfter) => {
    this.setState({
      processing: true
    });
    setTimeout(async () => {
      const images = await this.getImages(
        oldCount === 0 && this.props.emptyCallback
      );

      const loadedCount = images.length;

      if (loadedCount !== count && terminateAfter > 0) {
        this.delayedReload(oldCount, delay, count, terminateAfter - 1);
      } else {
        this.setState({
          processing: false
        });
        if (oldCount === 0 && this.props.emptyCallback) {
          this.props.emptyCallback(images);
        }
      }
    }, delay);
  };

  getRowHeight = () => {
    const galleryWidth = this.state.galleryWidth;
    if (galleryWidth < 500) {
      return 300;
    } else if (galleryWidth < 767) {
      return 250;
    } else if (galleryWidth < 1100) {
      return 300;
    } else if (galleryWidth < 1500) {
      return 350;
    } else {
      return 400;
    }
  };

  currentImageWillChange = async function(index) {
    let url = await getS3URL(
      this.state.images[index].srcId
    );
    let images = [...this.state.images];
    
    images[index].src = url;

    this.setState({ images });
    ;
  };

  tileViewportStyle = function() {
    return Object.assign(
      {
        width: this.props.item.vwidth,
        height: this.props.height,
        overflow: "hidden"
      },
      {}
    );
  };

  thumbnailStyle = function() {
    return {
      cursor: "pointer",
      width: this.props.item.scaletwidth,
      height: this.props.height,
      marginLeft: this.props.item.marginLeft,
      marginTop: 0
    };
  };

  render() {
    return (
      <div className="Photos">
        {this.props.compName ? <h2>{this.props.compName}</h2> : null}
        {this.props.showUpload ? (
          this.containsSelections() ? (
            <LoaderButton
              bsSize="large"
              bsStyle="danger"
              className="image-delete"
              isLoading={this.state.deleting}
              onClick={this.delete}
              icon="trash"
              text="Delete Selected"
              loadingText="Deleting..."
            />
          ) : (
            <FileUpload
              controlId="file"
              uploadBtnText="Upload Photos"
              multiple
              isLoading={this.state.uploading}
              loadingText="Uploading..."
              handleFileChange={this.handleFileChange}
              accept="image/*"
              type="file"
            />
          )
        ) : null}

        {this.state.processing ? (
          <h4 className="inline-loading">
            Just a sec, photos now being processed...
          </h4>
        ) : null}
        {this.state.images.length > 0 ? (
          <Gallery
            images={this.state.images}
            onSelectImage={this.onSelectImage}
            rowHeight={this.getRowHeight()}
            preloadNextImage={true}
            backdropClosesModal={true}
            thumbnailImageComponent={Thumbnail}
            margin={1}
            tileViewportStyle={this.tileViewportStyle}
            thumbnailStyle={this.thumbnailStyle}
            currentImageWillChange={this.currentImageWillChange}
          />
        ) : this.state.loading ? (
          <h4 className="inline-loading">Loading Photos...</h4>
        ) : (
          <h4>No Photos Uploaded.</h4>
        )}
      </div>
    );
  }
}

export default Photos;
