export const SET_AUTHENTICATED = "authentication/SET_AUTHENTICATED";
export const SET_AUTHENTICATING = "authentication/SET_AUTHENTICATING";
export const SET_ADMIN = "authentication/SET_ADMIN";
export const SET_CURRENT_USER = "authentication/SET_CURRENT_USER";

const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  isAdmin: false,
  currentUser: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated
      };

    case SET_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: action.isAuthenticating
      };

    case SET_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser
      };
    default:
      return state;
  }
};

export const setAuthenticated = isAuthenticated => {
  return { type: SET_AUTHENTICATED, isAuthenticated };
};

export const setAuthenticating = isAuthenticating => {
  return { type: SET_AUTHENTICATING, isAuthenticating };
};

export const setAdmin = isAdmin => {
  return { type: SET_ADMIN, isAdmin };
};

export const setCurrentUser = currentUser => {
  return { type: SET_CURRENT_USER, currentUser };
};
