import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./GolferRoundsList.css";

export default props => (
  <div className="GolferRoundsList">
    <Table striped bordered condensed>
      <thead>
        <tr>
          <th className="name-col">Course</th>
          <th className="date-col">Date</th>
          <th className="points-col">Points</th>
        </tr>
      </thead>
      <tbody>
        {[{}].concat(props.rounds).map((item, idx) =>
          idx !== 0 && item ? (
            <tr key={idx}>
              <td>
                <Link to={"/round/summary/" + item.pk.substring(13)}>
                  {item.courseName}
                </Link>
              </td>
              <td>{item.roundDate}</td>
              <td>
                <Link
                  to={
                    "/round/" + item.pk.substring(13) + "/scorecard/" + item.sk
                  }
                >
                  {item.pts}
                </Link>
              </td>
            </tr>
          ) : null
        )}
      </tbody>
    </Table>
  </div>
);
