import React, { Component } from "react";
import * as API from "API";
import HonoursBoard from "components/honours-board/HonoursBoard";
import PageHeader from "components/page-header/PageHeader";
import { HONOURS_CONTENT as PAGE_CONTENT } from "libs/content";
import "./Honours.css";

export default class Honours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      competitions: []
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const results = await API.competitionsLite();
      console.log(results);
      this.props.setLoading(false);
      this.setState({ competitions: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  render = () => (
    <div className="Honours">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <HonoursBoard
        compType="Bat & Ball Cup"
        comps={this.state.competitions}
      />
      <HonoursBoard
        compType="El Bandito Trophy"
        comps={this.state.competitions}
      />
      <HonoursBoard compType="Masters" comps={this.state.competitions} />
      <HonoursBoard compType="U.S. Open" comps={this.state.competitions} />
      <HonoursBoard compType="The Open" comps={this.state.competitions} />
      <HonoursBoard compType="PGA" comps={this.state.competitions} />
      <HonoursBoard compType="Players" comps={this.state.competitions} />
      <HonoursBoard compType="Captains Day" comps={this.state.competitions} />
    </div>
  );
}
