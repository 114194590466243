import React from "react";

export default props =>
  [{}].concat(props.listItems).map(
    (item, index) =>
      index !== 0 ? (
        <option
          key={
            props.keyName
              ? item[props.keyName]
              : typeof item === "object" ? item.id : item
          }
          value={
            props.keyName
              ? item[props.keyName]
              : typeof item === "object" ? item.id : item
          }
        >
          {props.renderItemText(item)}
        </option>
      ) : props.noBlank ? null : (
        <option key="-1" value="">
          Please select...
        </option>
      )
  );
