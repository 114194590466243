import { invokeApig } from "../libs/awsLib";

const apiName = "handicap";

// Handicap API
export const handicaps = () =>
  invokeApig({
    endpoint: apiName,
    path: "/handicap"
  });
export const handicapsByCompetitionId = competitionId =>
  competitionId && competitionId !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: "/handicap",
        queryParams: { competitionId: competitionId }
      })
    : [];
export const handicapsByYear = roundYear =>
  invokeApig({
    endpoint: apiName,
    path: "/handicap",
    queryParams: { roundYear: roundYear }
  });
export const getHandicap = id =>
  id !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: `/handicap/${id}`
      })
    : {};
export const saveHandicap = (id, handicap) =>
  invokeApig({
    endpoint: apiName,
    path: `/handicap/${id}`,
    method: "PUT",
    body: handicap
  });
export const createHandicap = handicap =>
  invokeApig({
    endpoint: apiName,
    path: "/handicap",
    method: "POST",
    body: handicap
  });
export const deleteHandicap = id =>
  invokeApig({
    endpoint: apiName,
    path: `/handicap/${id}`,
    method: "DELETE"
  });
