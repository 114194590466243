import React from "react";
import "./TableSort.css";

export default props => (
  <th
    className={["TableSort", props.className].join(" ")}
    onClick={props.sortState.sorter}
  >
    <span
      className={"table-sort fas fa-caret-" + props.sortState.direction}
    ></span>
    {props.children}
  </th>
);
