import React from "react";
import { NavLink } from "react-router-dom";

export default props => (
  <li className="nav-menu-item">
    <NavLink exact={true} activeClassName="active" {...props}>
      {props.icon ? <span className={props.icon + " nav-icon"} /> : null}{" "}
      {props.name}
    </NavLink>
  </li>
);
