import React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import "./BadgesList.css";

const getClassName = item => {
  let className = "fas fa-" + item.className;

  if (item.bad) {
    className = className + " pink";
  } else if (item.type === "rare") {
    className = className + " platinum";
  } else if (item.tier === 1 || item.type === "win") {
    className = className + " gold";
  } else if (!item.tier) {
    className = className + " standard";
  } else if (item.tier === 2) {
    className = className + " silver";
  } else if (item.tier === 3) {
    className = className + " bronze";
  }
  return className;
};

const badgeRow = item => (
  <span>
    <span className={getClassName(item)} />
    {item.desc}
    {item.new ? (
      <span className="badge">
        <span className="fas fa-star" /> New
      </span>
    ) : (
      ""
    )}
  </span>
);
const renderBadge = (idx, props, item, table) => (
  <div
    className={
      (props.detail ? " slim-list " : "") +
      " badge-item " +
      (props.unEarned ? " un-earned-badge" : "")
    }
    key={idx}
  >
    {table || props.unEarned ? (
      badgeRow(item)
    ) : item.badgeType === "round" ? (
      <Link to={"/round/" + item.roundId + "/scorecard/" + props.golferId}>
        {badgeRow(item)}
      </Link>
    ) : item.badgeType === "competition" ? (
      <Link to={"/competition/summary/" + item.competitionId}>
        {badgeRow(item)}
      </Link>
    ) : null}
  </div>
);

const showTable = props => (
  <Table striped bordered condensed>
    <thead>
      <tr>
        <th className="badge-col">Badge</th>
        <th className="course-col">Earned at</th>
      </tr>
    </thead>
    <tbody>
      {[{}].concat(props.badges).map((item, idx) =>
        idx !== 0 ? (
          <tr key={idx}>
            <td>{renderBadge(idx, props, item, true)}</td>
            <td>
              {item.badgeType === "round" ? (
                <Link
                  to={"/round/" + item.roundId + "/scorecard/" + props.golferId}
                >
                  {item.date} - {item.courseName}
                </Link>
              ) : item.badgeType === "competition" ? (
                <Link to={"/competition/summary/" + item.competitionId}>
                  {item.date} - {item.compName}
                </Link>
              ) : null}
            </td>
          </tr>
        ) : null
      )}
    </tbody>
  </Table>
);

export default props => (
  <div className="BadgesList">
    {props.limit ? (
      <div>
        {props.hideHeading ? null : (
          <h4>{props.heading ? props.heading : "My Badges"}</h4>
        )}
        {props.limit && props.badges.length > props.limit ? (
          <span>
            {" "}
            - <Link to={"/badges/" + props.golferId}>Show All</Link>
          </span>
        ) : null}
      </div>
    ) : null}

    {props.badges.length === 0 ? (
      <h4 className="no-badges">No badges to display.</h4>
    ) : null}
    {props.detail && props.badges.length > 0 ? showTable(props) : null}
    {[{}]
      .concat(props.badges)
      .map((item, idx) =>
        idx !== 0 && (!props.limit || idx <= props.limit)
          ? renderBadge(idx, props, item, false)
          : null
      )}
  </div>
);
