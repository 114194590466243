import React, { Component } from "react";
import * as API from "API";
import PageHeader from "components/page-header/PageHeader";
import EntityList from "components/entity-list/EntityList";
import { NEWS_CONTENT as PAGE_CONTENT } from "libs/content";

export default class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      news: []
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const results = await API.content("news");
      this.props.setLoading(false);
      this.setState({ news: results });
    } catch (e) {
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  displayHeader = news => {
    return news.newsText;
  };

  render = () => (
    <div className="News">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <EntityList
        {...this.props}
        isLoading={this.state.isLoading}
        listItems={this.state.news}
        type="news"
        header={this.displayHeader}
      />
    </div>
  );
}
