export const SET_LOADING = "utility/SET_AUTHENTICATING";
export const SET_ERROR = "utility/SET_ERROR";
export const SET_MENU_OPEN = "utility/SET_MENU_OPEN";
export const SET_ONLINE = "utility/SET_ONLINE";
export const SET_SW_UPDATE = "utility/SET_SW_UPDATE";

const initialState = {
  isLoading: false,
  hasError: false,
  menuOpen: false,
  online: true,
  isSWUpdate: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false
      };
    case SET_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        isLoading: false
      };
    case SET_MENU_OPEN:
      return {
        ...state,
        menuOpen: action.menuOpen
      };
    case SET_ONLINE:
      return {
        ...state,
        online: action.online
      };
    case SET_SW_UPDATE:
      return {
        ...state,
        isSWUpdate: action.isSWUpdate
      };

    default:
      return state;
  }
};

export const setLoading = isLoading => {
  return { type: SET_LOADING, isLoading };
};

export const setError = hasError => {
  return { type: SET_ERROR, hasError };
};

export const setMenuOpen = menuOpen => {
  return { type: SET_MENU_OPEN, menuOpen };
};

export const setOnline = online => {
  return { type: SET_ONLINE, online };
};

export const setSWUpdate = isSWUpdate => {
  return { type: SET_SW_UPDATE, isSWUpdate };
};
