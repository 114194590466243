import React, { Component } from "react";
import * as API from "API";
import PageHeader from "components/page-header/PageHeader";
import YearSelection from "components/year-selection/YearSelection";
import { ANNUAL_STATS_CONTENT as PAGE_CONTENT } from "libs/content";
import { Table, NavItem, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import BadgesList from "components/badges-list/BadgesList";
import TableSort from "components/table-sort/TableSort";
import { Link } from "react-router-dom";
import { formatRoundStats } from "libs/roundStatsHelper";
import "./AnnualStats.css";

class AnnualStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      annualStats: [],
      badgesStats: [],
      otherGolfer: null,
      golfers: [],
      sortDirection: {},
      activeYear: localStorage.getItem("activeStatsYear") || "all",
      resizeUpdater: this.updateDisplay.bind(this),
      tableStyle: {},
    };
  }

  componentDidMount = async () => {
    this.props.setLoading(true);
    this.updateDisplay();
    window.addEventListener("resize", this.state.resizeUpdater);

    if (this.props.match.params.id) {
      const golferResp = await API.getGolfer(this.props.match.params.id);
      this.setState({
        activeYear: "all",
        otherGolfer: golferResp,
      });
      this.fetchStats("all", false);
    } else {
      this.fetchStats(this.state.activeYear, false);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.state.resizeUpdater);
  };

  fetchStats = async (year, lastTry) => {
    try {
      this.props.setLoading(true);

      if (
        year === "all" &&
        !this.state.otherGolfer &&
        !this.props.currentUser
      ) {
        year = "2021";
        this.setState({
          activeYear: year,
        });
      }
      const annualStatsPromise =
        year === "all"
          ? API.golferAnnualStats(
              this.state.otherGolfer
                ? this.state.otherGolfer.id
                : this.props.currentUser.id
            )
          : API.annualStats(year);
      const golfersPromise = API.golfers();

      const annualStats = await annualStatsPromise;
      const golfers = await golfersPromise;

      if(!lastTry && (!annualStats || annualStats.length === 0) && new Date().getFullYear() > (year*1)){
        console.log("trying again");
        await  API.createAnnualStats(year);
        this.fetchStats(year, true);
        return;
      }
      this.props.setLoading(false);

      const combinedStats = annualStats.map((statsItem) => {
        const golfer = golfers.filter(
          (golfer) => golfer.id === statsItem.id
        )[0];
        statsItem.stats.roundStats = formatRoundStats(
          statsItem.stats.roundStats
        );
        return { ...statsItem, ...golfer };
      });
      this.setState({
        annualStats: combinedStats,
        badgesStats: [...combinedStats],
      });
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
    this.setState({ isLoading: false });
  };

  createStats = (selectedKey) => {
    API.annualStats(year);
  }
  
  

  handleYearSelect = (selectedKey) => {
    this.setState({ activeYear: selectedKey });
    try {
      localStorage.setItem("activeStatsYear", selectedKey);
    } catch (e) {
      console.log("Error setting local storage - activeStatsYear");
    }
    this.fetchStats(selectedKey, false);
  };

  updateDisplay = () => {
    this.setState({
      tableStyle: {
        maxHeight: Math.max(200, window.innerHeight - 40) + "px",
      },
      displayNumber:
        window.innerWidth <= 350 ? 0 : window.innerWidth <= 425 ? 1 : 4,
    });
  };

  filterBadges = (badges, best) => {
    let filtered = badges.filter((item) => item.earned);
    return best ? this.sortBadges(filtered) : filtered;
  };

  sortState = (nodes, prop) => {
    let state = {
      direction: this.state.sortDirection[prop],
      sorter: () => {
        const direction = !this.state.sortDirection[prop]
          ? "up"
          : this.state.sortDirection[prop] === "up"
          ? "down"
          : "up";
        const priority1 = direction === "down" ? -1 : 1;
        const priority2 = direction === "down" ? 1 : -1;
        const annualStats = [...this.state.annualStats].sort((a, b) => {
          for (let node of nodes) {
            a = a[node];
            b = b[node];
          }
          const aVal = a[prop];
          const bVal = b[prop];

          if ((!isNaN(aVal) && !isNaN(bVal)) || (isNaN(aVal) && isNaN(bVal))) {
            return aVal > bVal ? priority1 : aVal < bVal ? priority2 : 0;
          } else if (!isNaN(aVal)) {
            return priority2;
          } else if (!isNaN(bVal)) {
            return priority1;
          }
          return 0;
        });
        this.setState({
          annualStats: annualStats,
          sortDirection: {
            [prop]: direction,
          },
        });
      },
    };
    return state;
  };

  renderRow = (item, idx) => {
    const roundStats = item.stats.roundStats;
    const competitionStats = item.stats.competitionStats;
    return (
      <tr key={idx}>
        {this.state.activeYear === "all" ? (
          <th className="num">{item.year}</th>
        ) : (
          <th className="col">
            <Link to={"/stats/" + item.id}>
              {item.givenName + " " + item.familyName}
            </Link>
          </th>
        )}

        <td className="num">{roundStats.noOfRounds}</td>
        <td className="num">{roundStats.sScore}</td>
        <td className="col col-wide">
          <Link
            to={
              "/round/" + roundStats.bestRound.roundId + "/scorecard/" + item.id
            }
          >
            {roundStats.bestRound.score +
              " / " +
              roundStats.bestRound.stableford +
              " Pts" +
              " - " +
              roundStats.bestRound.courseName}
          </Link>
        </td>
        <td className={"num range-" + roundStats["avPar3ToParRange"]}>
          {roundStats.avPar3}
        </td>
        <td className={"num range-" + roundStats["avPar3PtsRange"]}>
          {roundStats.avPar3Pts}
        </td>
        <td className={"num range-" + roundStats["avPar4ToParRange"]}>
          {roundStats.avPar4}
        </td>
        <td className={"num range-" + roundStats["avPar4PtsRange"]}>
          {roundStats.avPar4Pts}
        </td>
        <td className={"num range-" + roundStats["avPar5ToParRange"]}>
          {roundStats.avPar5}
        </td>
        <td className={"num range-" + roundStats["avPar5PtsRange"]}>
          {roundStats.avPar5Pts}
        </td>
        <td
          className={
            "num " +
            (roundStats.frontNineSScore < roundStats.backNineSScore
              ? "less-than-avg"
              : roundStats.frontNineSScore > roundStats.backNineSScore
              ? "more-than-avg"
              : "")
          }
        >
          {roundStats.frontNineSScore}
        </td>
        <td
          className={
            "num " +
            (roundStats.frontNineSScore > roundStats.backNineSScore
              ? "less-than-avg"
              : roundStats.frontNineSScore < roundStats.backNineSScore
              ? "more-than-avg"
              : "")
          }
        >
          {roundStats.backNineSScore}
        </td>
        <td className="num">{roundStats.birdieCount}</td>
        <td className="num">{roundStats.eagleCount}</td>
        <td className="num">{roundStats.albatrossCount}</td>
        <td className="num">{roundStats.nrstPin}</td>
        <td className="num">{roundStats.nrstPinIn2}</td>
        <td className="num">{roundStats.lgestDr}</td>
        <td className="num">{competitionStats.noOfComps}</td>
        <td className="num">{competitionStats.wins}</td>
        <td className="num">{competitionStats.runnersUp}</td>
        <td className="num">{competitionStats.last}</td>
      </tr>
    );
  };

  render = () => (
    <div className="AnnualStats">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        subHeading={PAGE_CONTENT.SUB_HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <YearSelection
        activeKey={this.state.activeYear}
        onSelect={this.handleYearSelect}
        display={this.state.displayNumber}
        lastYear={new Date().getFullYear() - 1}
        startYear={2017}
        customNav={
          this.state.otherGolfer || this.props.currentUser ? (
            <NavItem key={"all"} eventKey={"all"}>
              {this.state.otherGolfer
                ? this.state.otherGolfer.givenName +
                  " " +
                  this.state.otherGolfer.familyName
                : "My Annual Stats"}
            </NavItem>
          ) : null
        }
      />
      <div
        id="table-scroll"
        className="table-scroll"
        style={this.state.tableStyle}
      >
        <Table striped bordered condensed>
          <thead>
            <tr>
              <th></th>
              <th colSpan="14">Round Stats</th>
              <th colSpan="3">Nearest & Longest</th>
              <th colSpan="4">Competition Stats</th>
            </tr>
            <tr>
              {this.state.activeYear === "all" ? (
                <TableSort
                  className="num"
                  sortState={this.sortState([], "year")}
                >
                  Year
                </TableSort>
              ) : (
                <TableSort
                  className="col"
                  sortState={this.sortState([], "givenName")}
                >
                  Name
                </TableSort>
              )}

              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "roundStats"],
                  "noOfRounds"
                )}
              >
                No. of Rounds
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "sScore")}
              >
                Stabkeford Points Avg.
              </TableSort>
              <TableSort
                className="col col-wide"
                sortState={this.sortState(
                  ["stats", "roundStats", "bestRound"],
                  "stableford"
                )}
              >
                Best Round
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "avPar3")}
              >
                Par 3 Average Score
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "avPar3Pts")}
              >
                Par 3 Average Points
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "avPar4")}
              >
                Par 4 Average Score
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "avPar4Pts")}
              >
                Par 4 Average Points
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "avPar5")}
              >
                Par 5 Average Score
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "avPar5Pts")}
              >
                Par 5 Average Points
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "roundStats"],
                  "frontNineSScore"
                )}
              >
                Front Nine Points Avg.
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "roundStats"],
                  "backNineSScore"
                )}
              >
                Back Nine Points Avg.
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "roundStats"],
                  "birdieCount"
                )}
              >
                Net Birdies
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "roundStats"],
                  "eagleCount"
                )}
              >
                Net Eagles
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "roundStats"],
                  "albatrossCount"
                )}
              >
                Net Albatrosses
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "nrstPin")}
              >
                Nearest the Pins
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "roundStats"],
                  "nrstPinIn2"
                )}
              >
                Nearest the Pin in Twos
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(["stats", "roundStats"], "lgestDr")}
              >
                Longest Drives
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "competitionStats"],
                  "noOfComps"
                )}
              >
                Appearances
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "competitionStats"],
                  "wins"
                )}
              >
                Wins
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "competitionStats"],
                  "runnersUp"
                )}
              >
                Runners Up
              </TableSort>
              <TableSort
                className="num"
                sortState={this.sortState(
                  ["stats", "competitionStats"],
                  "last"
                )}
              >
                Last Places
              </TableSort>
            </tr>
          </thead>
          <tbody>
            {[{}]
              .concat(this.state.annualStats)
              .map((item, idx) =>
                idx !== 0 ? this.renderRow(item, idx) : null
              )}
          </tbody>
        </Table>
      </div>

      {this.state.activeYear === "all" ? (
        <div className="annual-badges">
          <Row>
            {[{}].concat(this.state.badgesStats).map((item, idx) =>
              idx !== 0 && !isNaN(item.year) ? (
                <Col key={idx} className="col-xl-4 col-lg-4 col-md-6">
                  <h4>{item.year}</h4>
                  <BadgesList
                    badges={this.filterBadges(item.badges, false)}
                    golferId={this.props.currentUser.id}
                    hideHeading={true}
                    limit={100}
                  />
                </Col>
              ) : null
            )}
          </Row>
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(AnnualStats);
