import React from "react";
import { Link } from "react-router-dom";
import NewsItem from "components/news-item/NewsItem";
import "./News.css";

export default props => (
  <div className="News">
    <h4>{props.heading ? props.heading : "News"} </h4>
    {[{}].concat(props.items).map((item, idx) =>
      idx !== 0 && item ? (
        <NewsItem key={idx} new={item.newNews}>
          {item.link ? (
            <Link to={item.link}>{item.newsText}</Link>
          ) : (
            <span>{item.newsText}</span>
          )}
        </NewsItem>
      ) : null
    )}
  </div>
);
