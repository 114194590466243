import React, { Component } from "react";
import { Table } from "react-bootstrap";
import PageHeader from "components/page-header/PageHeader";
import { APPEARANCES_CONTENT as PAGE_CONTENT } from "libs/content";
import "./Appearances.css";

export default class Appearances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: false });
  };

  render = () => (
    <div className="Appearances">
      <PageHeader
        heading={PAGE_CONTENT.HEADING}
        icon={PAGE_CONTENT.HEADING_ICON}
      />
      <h3>El Bandito Trophy Appearances</h3>
      <Table striped bordered condensed>
        <tbody>
          <tr>
            <td className="appearances-count">10</td>
            <td>
              Simon Kidd
              <br />
              Julian Rozario
              <br />
              Nick Mole
            </td>
          </tr>
          <tr>
            <td className="appearances-count">9</td>
            <td>John Benjamin</td>
          </tr>
          <tr>
            <td className="appearances-count">8</td>
            <td>
              Jon Mole
              <br />
              Steve Heath
            </td>
          </tr>
          <tr>
            <td className="appearances-count">7</td>
            <td>James Brown</td>
          </tr>
          <tr>
            <td className="appearances-count">6</td>
            <td>Sean Connors</td>
          </tr>
          <tr>
            <td className="appearances-count">4</td>
            <td>
              Steve Wells
              <br />
              John Cole
              <br />
              Rob Hills
              <br />
              David Graham
              <br />
              Mark Trasler
            </td>
          </tr>
          <tr>
            <td className="appearances-count">3</td>
            <td>
              Mark Chenais
            </td>
          </tr>
          <tr>
            <td className="appearances-count">2</td>
            <td>Gavin Goodchild</td>
          </tr>
          <tr>
            <td className="appearances-count">1</td>
            <td>
              Tim Rozario
              <br />
              Richard Lawrence
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
