import React, { Component } from "react";
import * as API from "API";
import BadgesList from "components/badges-list/BadgesList";
import { Breadcrumb } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    try {
      this.props.setLoading(true);

      const golferPromise = API.getGolfer(this.props.match.params.id);
      const golferDetailsPromise = API.golferDetails(
        this.props.match.params.id
      );

      const golferResp = await golferPromise;
      const golferDetailsResp = await golferDetailsPromise;

      this.props.setLoading(false);

      const golfer = {
        id: golferResp.id || "",
        givenName: golferResp.givenName || "",
        familyName: golferResp.familyName || "",
        handicap: golferResp.handicap || "",
        badges: golferDetailsResp.badges
      };
      this.setState(golfer);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  showUnEarnedBadges = () => {
    return this.state.badges.filter(item => !item.earned);
  };

  filterBadges = () => {
    let filtered = this.state.badges.filter(item => item.earned);
    return filtered;
  };

  render = () => (
    <div className="Badges">
      <Breadcrumb>
        <Breadcrumb.Item onClick={e => this.props.history.goBack()}>
          Back
        </Breadcrumb.Item>
        <Breadcrumb.Item active className="golfer-breadcrumb">
          {this.state.givenName + " " + this.state.familyName}
        </Breadcrumb.Item>
      </Breadcrumb>

      <form>
        {this.state.badges ? (
          <div>
            <BadgesList
              badges={this.filterBadges(this.state.badges)}
              golferId={this.state.id}
              detail={true}
            />
            <hr />
            <h3>Yet to be won</h3>
            <BadgesList
              badges={this.showUnEarnedBadges(this.state.badges)}
              golferId={this.state.id}
              unEarned={true}
            />
          </div>
        ) : null}
      </form>
    </div>
  );
}

export default withRouter(Badges);
