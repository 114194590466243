import { invokeApig } from "../libs/awsLib";

const apiName = "content";

export const content = contentType =>
  invokeApig({
    endpoint: apiName,
    path: "/content/" + contentType
  });
export const getNews = id =>
  id !== "new"
    ? invokeApig({
        endpoint: apiName,
        path: `/content/news/${id}`
      })
    : {};
export const saveNews = (id, news) =>
  invokeApig({
    endpoint: apiName,
    path: `/content/news/${id}`,
    method: "PUT",
    body: news
  });
export const createNews = news =>
  invokeApig({
    endpoint: apiName,
    path: "/content/news",
    method: "POST",
    body: news
  });
export const deleteNews = id =>
  invokeApig({
    endpoint: apiName,
    path: `/content/news/${id}`,
    method: "DELETE"
  });
