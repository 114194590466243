import React, { Component } from "react";
import * as API from "API";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import FormRow from "components/form-row/FormRow";
import FormAlert from "components/form-alert/FormAlert";
import GolferList from "components/golfer-list/GolferList";
import SelectGolfers from "components/select-golfers/SelectGolfers";
import EntityList from "components/entity-list/EntityList";
import DropdownOptions from "components/dropdown-options/DropdownOptions";
import {
  FormGroup,
  Checkbox,
  Radio,
  Glyphicon,
  ControlLabel,
  HelpBlock
} from "react-bootstrap";
import FormSubmitButtons from "components/form-submit-buttons/FormSubmitButtons";
import { lettersRegex, validateInput } from "libs/formUtils";
import "./Competition.css";

class Competition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compName: "",
      compType: "",
      dateFrom: "",
      dateTo: "",
      teams: [],
      rounds: [],
      golfers: [],
      compHidden: false,
      winner: null,
      runnerUp: null,
      lastPlace: null,
      parThreeChampWinner: null,
      nearestPinWinner: null,
      nearestPinIn2Winner: null,
      longestDriveWinner: null,
      ryderCupWinner: null,
      ryderCupIndividualWinner: null,
      individualComp: "individualStableford",
      ryderCup: false,
      excludeRyderCupScoreFromIndividual: false,
      competitionDetails: true,
      errors: {}
    };
  }

  componentWillMount = async () => {
    if (!this.props.isAdmin) {
      this.props.history.replace(
        "/competition/summary/" + this.props.match.params.id
      );
    }
  };

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const resultsPromise = API.getCompetition(this.props.match.params.id);
      const golfersPromise = API.golfers();
      const roundsPromise = API.roundsLiteByCompetitionId(
        this.props.match.params.id
      );
      const results = await resultsPromise;
      const golfers = await golfersPromise;
      const rounds = await roundsPromise;
      this.props.setLoading(false);
      const competition = {
        id: results.id || "",
        compName: results.compName || "",
        compType: results.compType || "",
        dateFrom: results.dateFrom || "",
        dateTo: results.dateTo || "",
        teams: results.teams || [],
        rounds: rounds || [],
        individualComp: results.individualComp || "individualStableford",
        ryderCup: results.ryderCup || false,
        excludeRyderCupScoreFromIndividual:
          results.excludeRyderCupScoreFromIndividual || false,
        competitionDetails:
          results.competitionDetails !== null &&
          typeof results.competitionDetails !== "undefined"
            ? results.competitionDetails
            : true,
        compHidden: results.compHidden ? results.compHidden : false,
        winner: results.winner,
        runnerUp: results.runnerUp,
        lastPlace: results.lastPlace,
        parThreeChampWinner: results.parThreeChampWinner,
        nearestPinWinner: results.nearestPinWinner,
        nearestPinIn2Winner: results.nearestPinIn2Winner,
        longestDriveWinner: results.longestDriveWinner,
        ryderCupWinner: results.ryderCupWinner,
        ryderCupIndividualWinner: results.ryderCupIndividualWinner,
        golfers: golfers,
        selectedTeam: null
      };
      this.setState(competition);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  validateForm = () => {
    return (
      this.state.compName !== "" &&
      this.state.compType !== "" &&
      !isNaN(Date.parse(this.state.dateFrom)) &&
      (this.state.dateTo === "" || !isNaN(Date.parse(this.state.dateTo))) &&
      (!this.state.competitionDetails ||
        (this.state.competitionDetails && this.state.teams.length > 0)) &&
      (this.state.competitionDetails ||
        (!this.state.competitionDetails &&
          this.state.winner &&
          this.state.winner.id))
    );
  };

  handleGolferChange = event => {
    const golferId = event.target.value,
      golfer = golferId
        ? this.state.golfers.filter(golfer => golfer.id === golferId)[0]
        : null;

    this.setState({
      dirty: true,
      [event.target.id]: {
        id: golfer.id,
        givenName: golfer.givenName,
        familyName: golfer.familyName
      }
    });
  };

  removeTeam = event => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this team?"
    );
    if (!confirmed) {
      return;
    }
    const teams = [...this.state.teams],
      team = { ...this.state.selectedTeam },
      teamIndex = teams.map(selectedTeam => selectedTeam.nbr).indexOf(team.nbr);

    teams.splice(teamIndex, 1);
    this.setState({
      teams: teams,
      selectedTeam: null,
      dirty: true
    });
  };

  confirmTeam = event => {
    const teams = [...this.state.teams],
      team = { ...this.state.selectedTeam },
      teamIndex = teams.map(selectedTeam => selectedTeam.nbr).indexOf(team.nbr);

    team.name = team.name === "" ? "Team " + team.nbr : team.name;
    if (teamIndex === -1) {
      teams.push(team);
    } else {
      teams[teamIndex] = team;
    }

    for (let i = team.golfers.length - 1; i >= 0; --i) {
      if (
        !team.golfers[i].id ||
        this.state.golfers.filter(golfer => golfer.id === team.golfers[i].id)
          .length === -1
      ) {
        team.golfers.splice(i, 1);
      }
    }
    this.setState({
      teams: teams,
      selectedTeam: null,
      dirty: true
    });
  };

  isGolferSelected = golfer => {
    return (
      this.state.selectedTeam.golfers.filter(
        selectedGolfer => selectedGolfer.id === golfer.id
      ).length === 1
    );
  };

  addTeam = event => {
    const newTeam = {
      nbr: this.state.teams.length,
      name: "Team " + (this.state.teams.length + 1),
      golfers: []
    };
    this.setState({
      selectedTeam: newTeam,
      dirty: true
    });
    window.scrollTo(0, 0);
  };

  editTeam = team => {
    const teamForEdit = { ...team };
    teamForEdit.golfers = [...teamForEdit.golfers];
    this.setState({
      selectedTeam: teamForEdit
    });
    window.scrollTo(0, 0);
  };

  handleChange = event => {
    this.setState({
      dirty: true,
      [event.target.id]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    });
  };

  handleTeamNameChange = event => {
    const team = { ...this.state.selectedTeam };
    team.name = event.target.value;
    this.setState({
      selectedTeam: team,
      dirty: true
    });
  };

  alreadyInTeam = golferId => {
    for (let team of this.state.teams) {
      for (let g of team.golfers) {
        if (g.id === golferId) {
          return team.name;
        }
      }
    }
    return false;
  };

  toggleGolfer = golfer => {
    const team = { ...this.state.selectedTeam },
      golferIndex = team.golfers
        .map(selectedGolfer => selectedGolfer.id)
        .indexOf(golfer.id);

    if (golferIndex === -1) {
      team.golfers.push({
        familyName: golfer.familyName,
        givenName: golfer.givenName,
        id: golfer.id,
        gender: golfer.gender
      });
    } else {
      team.golfers.splice(golferIndex, 1);
    }
    this.setState({
      selectedTeam: team,
      dirty: true
    });
  };

  handleFocus = event => {
    let errors = { ...this.state.errors };
    errors[event.target.id] = null;
    this.setState({
      errors: errors,
      formError: false
    });
  };

  handleBlur = event => {
    const regex = event.target.attributes.getNamedItem("data-regex"),
      stateProp = this.state[event.target.id];
    let errors = { ...this.state.errors };

    errors[event.target.id] = !validateInput(
      regex ? regex.value : null,
      !stateProp ? "" : typeof stateProp === "object" ? stateProp.id : stateProp
    )
      ? "error"
      : null;

    this.setState({
      errors: errors
    });
  };

  handleDateBlur = event => {
    const val = Date.parse(event.target.value);
    let errors = { ...this.state.errors };
    errors[event.target.id] =
      (event.target.id === "dateTo" && event.target.value === "") || !isNaN(val)
        ? null
        : "error";

    this.setState({
      errors: errors
    });
  };

  individualCompChange = event => {
    this.setState({
      individualComp: event.target.id,
      dirty: true
    });
  };

  competitionDetailsChange = event => {
    this.setState({
      dirty: true,
      competitionDetails:
        (event.target.id === "competitionDetailsYes" &&
          event.target.value === "on") ||
        (event.target.id === "competitionDetailsNo" &&
          event.target.value !== "on")
    });
  };

  createOrEditRound = async event => {
    const href = event.currentTarget.getAttribute("href");
    event.preventDefault();
    this.props.setLoading(true);
    await this.intermediarySave();
    this.props.history.push(href);
  };

  prepareCompetition = () => ({
    compName: this.state.compName,
    compType: this.state.compType,
    dateFrom: this.state.dateFrom,
    dateTo: this.state.dateTo,
    compHidden: this.state.compHidden,
    teams: this.state.competitionDetails ? this.state.teams : null,
    individualComp: this.state.competitionDetails
      ? this.state.individualComp
      : false,
    ryderCup: this.state.teams.length === 2 ? this.state.ryderCup : false,
    excludeRyderCupScoreFromIndividual:
      this.state.teams.length === 2 && this.state.ryderCup
        ? this.state.excludeRyderCupScoreFromIndividual
        : false,
    competitionDetails: this.state.competitionDetails,
    winner: this.state.winner ? this.state.winner : null,
    runnerUp: this.state.runnerUp ? this.state.runnerUp : null,
    lastPlace: !this.state.competitionDetails ? this.state.lastPlace : null,
    parThreeChampWinner: !this.state.competitionDetails
      ? this.state.parThreeChampWinner
      : null,
    nearestPinWinner: !this.state.competitionDetails
      ? this.state.nearestPinWinner
      : null,
    nearestPinIn2Winner: !this.state.competitionDetails
      ? this.state.nearestPinIn2Winner
      : null,
    longestDriveWinner: !this.state.competitionDetails
      ? this.state.longestDriveWinner
      : null,
    ryderCupWinner: !this.state.competitionDetails
      ? this.state.ryderCupWinner
      : null,
    ryderCupIndividualWinner: !this.state.competitionDetails
      ? this.state.ryderCupIndividualWinner
      : null
  });

  intermediarySave = async () => {
    let competition = this.prepareCompetition();
    let savedCompetition = null;
    const loadingKey = "isLoading";
    this.setState({ [loadingKey]: true });
    try {
      if (!this.state.id || this.state.id === "new") {
        savedCompetition = await API.createCompetition(competition);
      } else {
        savedCompetition = await API.saveCompetition(
          this.state.id,
          competition
        );
      }
      this.setState({
        [loadingKey]: false,
        id: savedCompetition.id || this.state.id
      });
      if (savedCompetition.id) {
        this.props.history.replace("/competition/" + savedCompetition.id);
      }
    } catch (e) {
      this.setState({ [loadingKey]: false, formError: e.message });
    }
  };

  handleSubmit = async event => {
    let competition = this.prepareCompetition();
    competition.fullSave = true;
    this.setState({ isLoading: true });
    try {
      if (this.props.match.params.id === "new") {
        await API.createCompetition(competition);
      } else {
        await API.saveCompetition(this.state.id, competition);
      }
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isLoading: false, formError: e.message });
    }
  };

  handleDelete = async event => {
    event.preventDefault();
    const confirmed = window.confirm(
      "Are you sure you want to delete this competition?"
    );
    if (!confirmed) {
      return;
    }
    this.setState({ isDeleting: true });
    try {
      await API.deleteCompetition(this.state.id);
      this.props.history.goBack();
    } catch (e) {
      this.setState({ isDeleting: false, formError: e.message });
    }
  };

  displayRoundHeader = round => {
    return round.courseName ? round.courseName : "";
  };

  areRoundsComplete = () => {
    return (
      !this.state.compHidden &&
      this.state.rounds.length > 0 &&
      this.state.rounds.filter(r => !r.complete).length === 0
    );
  };

  displayRoundText = round => (
    <span>
      {round.roundDate}{" "}
      {round.complete ? (
        <span className="round-complete text-muted">
          <Glyphicon glyph="ok" className="text-muted" /> Complete
        </span>
      ) : null}
    </span>
  );

  renderSelectedTeam = () => (
    <SelectGolfers
      formError={this.state.formError}
      homeClick={e => this.setState({ selectedTeam: null })}
      homeName={this.state.compName}
      name={this.state.selectedTeam.name}
      type="team"
      confirm={this.confirmTeam}
      confirmDisabled={this.state.selectedTeam.golfers.length === 0}
      showDelete={
        this.state.teams.filter(
          existingTeam => existingTeam.nbr === this.state.selectedTeam.nbr
        ).length === 1
          ? true
          : null
      }
      remove={this.removeTeam}
      golfers={this.state.golfers}
      toggleGolfer={this.toggleGolfer}
      isGolferSelected={this.isGolferSelected}
      alreadyIn={this.alreadyInTeam}
    >
      <FormRow
        controlId="teamName"
        errors={this.state.errors}
        label="Team Name"
        value={this.state.selectedTeam.name}
        onChange={this.handleTeamNameChange}
        regex={lettersRegex}
        validationMessage="Please enter a team name."
      />
    </SelectGolfers>
  );

  renderInitialForm = () => (
    <form>
      <FormAlert formError={this.state.formError} />
      <h3>Competition Details</h3>
      {this.state.id &&
      this.state.rounds &&
      this.state.rounds.length > 0 &&
      !this.state.dirty ? (
        <FormGroup>
          <Link to={"/competition/summary/" + this.state.id}>
            View Competition Summary
          </Link>
        </FormGroup>
      ) : null}
      <FormRow
        controlId="compName"
        errors={this.state.errors}
        label="Competition Name"
        value={this.state.compName}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        regex={lettersRegex}
        validationMessage="Please enter a competition name."
      />
      <FormRow
        componentClass="select"
        placeholder="select"
        controlId="compType"
        errors={this.state.errors}
        label="Competition Type"
        value={this.state.compType}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        regex={lettersRegex}
        validationMessage="Please select a competition type."
      >
        <option value="">Please select...</option>
        <option value="Bat & Ball Cup">Bat & Ball Cup</option>
        <option value="Masters">Masters</option>
        <option value="U.S. Open">U.S. Open</option>
        <option value="The Open">The Open</option>
        <option value="PGA">PGA</option>
        <option value="Players">Players</option>
        <option value="Captains Day">Captains Day</option>
        <option value="El Bandito Trophy">El Bandito Trophy</option>
        <option value="Social">Social</option>
        <option value="Other">Other</option>
      </FormRow>
      <FormRow
        controlId="dateFrom"
        errors={this.state.errors}
        label="Date From"
        value={this.state.dateFrom}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleDateBlur}
        validationMessage="Please enter a valid date."
        mask="1111-11-11"
        placeholder="YYYY-MM-DD"
      />
      <FormRow
        controlId="dateTo"
        errors={this.state.errors}
        label="Date To (Optional)"
        value={this.state.dateTo}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleDateBlur}
        validationMessage="Please enter a valid date."
        mask="1111-11-11"
        placeholder="YYYY-MM-DD"
      />
      <FormGroup>
        <ControlLabel>
          Will you have competition details including teams, rounds and scores
          to enter?
        </ControlLabel>
        <Radio
          id="competitionDetailsYes"
          name="competitionDetails"
          checked={this.state.competitionDetails}
          onChange={this.competitionDetailsChange}
        >
          Yes
        </Radio>
        <Radio
          id="competitionDetailsNo"
          name="competitionDetails"
          checked={!this.state.competitionDetails}
          onChange={this.competitionDetailsChange}
        >
          No
        </Radio>
      </FormGroup>
      {!this.state.competitionDetails ? (
        <div>
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="winner"
            label="Winner"
            regex={lettersRegex}
            errors={this.state.errors}
            value={this.state.winner ? this.state.winner.id : ""}
            onChange={this.handleGolferChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            validationMessage="Please enter a winner."
          >
            <DropdownOptions
              listItems={this.state.golfers}
              renderItemText={item => item.givenName + " " + item.familyName}
            />
          </FormRow>
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="runnerUp"
            label="Runner Up (Optional)"
            value={this.state.runnerUp ? this.state.runnerUp.id : ""}
            onChange={this.handleGolferChange}
          >
            <DropdownOptions
              listItems={this.state.golfers}
              renderItemText={item => item.givenName + " " + item.familyName}
            />
          </FormRow>
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="lastPlace"
            label="Last Place (Optional)"
            value={this.state.lastPlace ? this.state.lastPlace.id : ""}
            onChange={this.handleGolferChange}
          >
            <DropdownOptions
              listItems={this.state.golfers}
              renderItemText={item => item.givenName + " " + item.familyName}
            />
          </FormRow>
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="parThreeChampWinner"
            label="Par Three Champ Winner (Optional)"
            value={
              this.state.parThreeChampWinner
                ? this.state.parThreeChampWinner.id
                : ""
            }
            onChange={this.handleGolferChange}
          >
            <DropdownOptions
              listItems={this.state.golfers}
              renderItemText={item => item.givenName + " " + item.familyName}
            />
          </FormRow>
          <FormRow
            controlId="ryderCupWinner"
            label="Ryder Cup Winner (Optional)"
            value={this.state.ryderCupWinner}
            onChange={this.handleChange}
          />
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="ryderCupIndividualWinner"
            label="Ryder Cup Individual Winner (Optional)"
            value={
              this.state.ryderCupIndividualWinner
                ? this.state.ryderCupIndividualWinner.id
                : ""
            }
            onChange={this.handleGolferChange}
          >
            <DropdownOptions
              listItems={this.state.golfers}
              renderItemText={item => item.givenName + " " + item.familyName}
            />
          </FormRow>
          <h3>Nearest &amp; Longest</h3>
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="nearestPinWinner"
            label="Nearest The Pin Winner (Optional)"
            value={
              this.state.nearestPinWinner ? this.state.nearestPinWinner.id : ""
            }
            onChange={this.handleGolferChange}
          >
            <DropdownOptions
              listItems={this.state.golfers}
              renderItemText={item => item.givenName + " " + item.familyName}
            />
          </FormRow>
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="nearestPinIn2Winner"
            label="Nearest The Pin In Two Winner (Optional)"
            value={
              this.state.nearestPinIn2Winner
                ? this.state.nearestPinIn2Winner.id
                : ""
            }
            onChange={this.handleGolferChange}
          >
            <DropdownOptions
              listItems={this.state.golfers}
              renderItemText={item => item.givenName + " " + item.familyName}
            />
          </FormRow>
          <FormRow
            componentClass="select"
            placeholder="select"
            controlId="longestDriveWinner"
            label="Longest Drive Winner (Optional)"
            value={
              this.state.longestDriveWinner
                ? this.state.longestDriveWinner.id
                : ""
            }
            onChange={this.handleGolferChange}
          >
            <DropdownOptions
              listItems={this.state.golfers}
              renderItemText={item => item.givenName + " " + item.familyName}
            />
          </FormRow>
        </div>
      ) : (
        <div>
          <hr />
          <Checkbox
            id="compHidden"
            checked={this.state.compHidden}
            onChange={this.handleChange}
          >
            Hide competition results from players
          </Checkbox>
          {this.state.compName.length > 0 ? <h3>Teams</h3> : null}
          <GolferList
            listItems={this.state.teams}
            edit={this.editTeam}
            addClick={this.addTeam}
            type="team"
          />
          <hr />
          <h3>Competition Types</h3>
          <FormGroup>
            <Radio
              id="individualStableford"
              name="radioGroup"
              checked={this.state.individualComp === "individualStableford"}
              onChange={this.individualCompChange}
            >
              Individual Stableford
            </Radio>
            <Radio
              id="individualDropLowRound"
              name="radioGroup"
              checked={this.state.individualComp === "individualDropLowRound"}
              onChange={this.individualCompChange}
            >
              Individual Stableford - Drop Low Round
            </Radio>
            <Radio
              id="individualPoints"
              name="radioGroup"
              checked={this.state.individualComp === "individualPoints"}
              onChange={this.individualCompChange}
            >
              Individual Points
            </Radio>
          </FormGroup>
          <FormGroup>
            {this.state.teams.length === 2 ? (
              <Checkbox
                id="ryderCup"
                checked={this.state.ryderCup}
                onChange={this.handleChange}
              >
                Ryder Cup
              </Checkbox>
            ) : null}
          </FormGroup>
          {this.state.ryderCup ? (
            <FormGroup>
              <Checkbox
                id="excludeRyderCupScoreFromIndividual"
                checked={this.state.excludeRyderCupScoreFromIndividual}
                onChange={this.handleChange}
              >
                Excluded Ryder Cup Scores From Individual
              </Checkbox>
            </FormGroup>
          ) : null}

          {this.state.compName.length > 0 ? <h3>Rounds</h3> : null}
          <EntityList
            {...this.props}
            disabled={!this.validateForm()}
            listItems={this.state.rounds}
            type="round"
            addClick={this.createOrEditRound}
            rowClick={this.createOrEditRound}
            header={this.displayRoundHeader}
            text={this.displayRoundText}
          />
        </div>
      )}
      {this.areRoundsComplete() ? (
        <HelpBlock className="finalise-note">
          Finalising the competition will update the competition stats.
        </HelpBlock>
      ) : null}
      <FormSubmitButtons
        disabled={!this.validateForm()}
        isLoading={this.state.isLoading}
        label="Competition"
        overrideLabel={this.areRoundsComplete() ? "Finalise Competition" : ""}
        entityId={this.state.id}
        isDeleting={this.state.isDeleting}
        onDeleteClick={this.handleDelete}
        onSubmit={this.handleSubmit.bind(this)}
      />
    </form>
  );

  render = () => (
    <div className="Competition">
      {this.state.selectedTeam
        ? this.renderSelectedTeam()
        : this.renderInitialForm()}
    </div>
  );
}

export default withRouter(Competition);
