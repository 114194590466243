import React from "react";
import { Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import "./YearSelection.css";

const yearsToDisplay = 3;

const lastYears = props => {
  var currentYear = props.lastYear ? props.lastYear : new Date().getFullYear(),
    years = [],
    startYear = props.startYear ? props.startYear : 2011;
  while (
    years.length < (!isNaN(props.display) ? props.display : yearsToDisplay) &&
    currentYear >= startYear
  ) {
    years.push(currentYear);
    currentYear = currentYear - 1;
  }
  return years;
};

const previousYears = props => {
  var currentYear = props.lastYear ? props.lastYear : new Date().getFullYear(),
    years = [],
    startYear = props.startYear ? props.startYear : 2011;
  currentYear =
    currentYear - (!isNaN(props.display) ? props.display : yearsToDisplay);
  while (currentYear >= startYear) {
    years.push(currentYear);
    currentYear = currentYear - 1;
  }
  return years;
};

const renderLastYears = props => {
  return [{}].concat(lastYears(props)).map((year, index) =>
    index !== 0 ? (
      <NavItem key={year} eventKey={year}>
        {year}
      </NavItem>
    ) : null
  );
};

const renderPreviousYears = props => {
  const prev = previousYears(props);
  return prev.length > 0 ? (
    <NavDropdown eventKey="previousYears" title="Previous" id="nav-dropdown">
      {[{}].concat(prev).map((year, index) =>
        index !== 0 ? (
          <MenuItem key={year} eventKey={year}>
            {year}
          </MenuItem>
        ) : null
      )}
    </NavDropdown>
  ) : null;
};

export default props => (
  <Nav
    className="YearSelection"
    bsStyle="pills"
    activeKey={
      props.activeKey
        ? isNaN(props.activeKey)
          ? props.activeKey
          : props.activeKey * 1
        : props.lastYear
        ? props.lastYear
        : new Date().getFullYear()
    }
    onSelect={props.onSelect}
  >
    {props.customNav ? props.customNav : null}
    {renderLastYears(props)}
    {renderPreviousYears(props)}
  </Nav>
);
