import React from "react";
import { Button } from "react-bootstrap";
import "./PageError.css";

const reload = event => {
  window.location.reload();
};

export default props => (
  <div className="PageError">
    <h1>Soz.</h1>
    <div>There was an error loading the page.</div>
    <Button className="reload-btn btn-primary btn-lg" onClick={reload}>
      Try a reload
    </Button>
  </div>
);
