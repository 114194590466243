import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getS3URL } from "libs/awsLib";

export default class Thumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: ""
    };
  }
  preLoad = async () => {
    const src = await getS3URL(this.props.item.thumbnail);
    this.setState({
      src: src
    });
  };
  render = () => (
    <LazyLoadImage
      beforeLoad={this.preLoad}
      {...this.props.imageProps}
      src={this.state.src}
      key={this.props.imageProps.src}
    />
  );
}
