export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "eu-west-1",
    BUCKET: "batandballgolf-photos"
  },
  apiGateway: {
    COMPETITION_URL:
      "https://ibm6y5ykj4.execute-api.eu-west-1.amazonaws.com/prod",
    CONTENT_URL: "https://8tmxdxfp65.execute-api.eu-west-1.amazonaws.com/prod",
    COURSE_URL: "https://zefs06bs95.execute-api.eu-west-1.amazonaws.com/prod",
    GOLFER_URL: "https://jipwqd3q06.execute-api.eu-west-1.amazonaws.com/prod",
    HANDICAP_URL: "https://2wg3oftcyj.execute-api.eu-west-1.amazonaws.com/prod",
    PHOTOS_URL: "https://dq7mjfieef.execute-api.eu-west-1.amazonaws.com/prod",
    ROUND_URL: "https://zr1cbqsdhb.execute-api.eu-west-1.amazonaws.com/prod",
    SCORES_URL: "https://q16gjcx1q9.execute-api.eu-west-1.amazonaws.com/prod",
    ADMIN_URL: "https://zgg8j6cpt5.execute-api.us-east-1.amazonaws.com/prod",
    ADMIN_REGION: "us-east-1",
    REGION: "eu-west-1"
  },
  cognito: {
    USER_POOL_ID: "us-east-1_bkNp9kWro",
    APP_CLIENT_ID: "2p0c9cb27pdvslpsbrpqql52mc",
    REGION: "us-east-1",
    IDENTITY_POOL_ID: "us-east-1:c9a04f06-0f0b-4e85-add0-21b0175029bb"
  },
  social: {
    FB: "598083757272733"
  }
};
