import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "store";
import App from "App";
import Amplify from "aws-amplify"; 
import config from "./config";
import "index.css";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "competition",
        endpoint: config.apiGateway.COMPETITION_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "golfer",
        endpoint: config.apiGateway.GOLFER_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "course",
        endpoint: config.apiGateway.COURSE_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "round",
        endpoint: config.apiGateway.ROUND_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "handicap",
        endpoint: config.apiGateway.HANDICAP_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "photos",
        endpoint: config.apiGateway.PHOTOS_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "scores",
        endpoint: config.apiGateway.SCORES_URL,
        region: config.apiGateway.REGION
      },
      {
        name: "content",
        endpoint: config.apiGateway.CONTENT_URL,
        region: config.apiGateway.REGION
      }
      ,
      {
        name: "admin",
        endpoint: config.apiGateway.ADMIN_URL,
        region: config.apiGateway.ADMIN_REGION
      }
    ]
  }
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
