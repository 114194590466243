import React from "react";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";

export default props => (
  <FormGroup
    controlId={props.controlId}
    bsSize="large"
    validationState={
      props.errors ? props.errors[props.controlId] : props.validationState
    }
  >
    {props.label ? <ControlLabel>{props.label}</ControlLabel> : null}
    {props.mask ? (
      <MaskedFormControl
        type="tel"
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        mask={props.mask}
        placeholder={props.placeholder}
      >
        {props.children}
      </MaskedFormControl>
    ) : (
      <FormControl
        autoFocus={props.autoFocus}
        componentClass={props.componentClass}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        data-regex={props.regex}
        placeholder={props.placeholder}
        className={props.className}
        min={props.min}
        max={props.max}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
        step={props.step}
        multiple={props.multiple}
        accept={props.accept}
        capture={props.capture}
      >
        {props.children}
      </FormControl>
    )}
    {props.help}
    {props.validationMessage ? (
      <FormControl.Feedback
        className={props.componentClass === "select" ? "select-feedback" : ""}
      />
    ) : null}
    {props.validationMessage ? (
      <div className="error-message text-danger">{props.validationMessage}</div>
    ) : null}
    {props.passwordValidation}
  </FormGroup>
);
