import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "containers/unauthenticated/login/Login";
import Signup from "containers/unauthenticated/signup/Signup";
import ConfirmAccount from "containers/unauthenticated/confirm-account/ConfirmAccount";
import ForgottenPassword from "containers/unauthenticated/forgotten-password/ForgottenPassword";
import Home from "containers/authenticated/home/Home";
import GolferRounds from "containers/authenticated/golfer-rounds/GolferRounds";
import Badges from "containers/authenticated/badges/Badges";
import Calendar from "containers/authenticated/calendar/Calendar";
import Gallery from "containers/authenticated/gallery/Gallery";
import Golfers from "containers/authenticated/golfers/Golfers";
import Golfer from "containers/authenticated/golfer/Golfer";
import Courses from "containers/authenticated/courses/Courses";
import Course from "containers/authenticated/course/Course";
import Competitions from "containers/authenticated/competitions/Competitions";
import Competition from "containers/authenticated/competition/Competition";
import CompetitionSummary from "containers/authenticated/competition-summary/CompetitionSummary";
import CompetitionSummaryByType from "./containers/authenticated/competition-summary-by-type/CompetitionSummaryByType";
import Rounds from "containers/authenticated/rounds/Rounds";
import Honours from "containers/authenticated/honours/Honours";
import Handicap from "containers/authenticated/handicap/Handicap";
import Handicaps from "containers/authenticated/handicaps/Handicaps";
import GolfersDetail from "containers/authenticated/golfers-detail/GolfersDetail";
import Appearances from "containers/authenticated/appearances/Appearances";
import Round from "containers/authenticated/round/Round";
import RoundSummary from "containers/authenticated/round-summary/RoundSummary";
import Scorecard from "containers/authenticated/scorecard/Scorecard";
import ThreeBallSixesScorecard from "containers/authenticated/three-ball-sixes-scorecard/ThreeBallSixesScorecard";
import CompetitionRules from "containers/authenticated/competition-rules/CompetitionRules";
import News from "containers/authenticated/news/News";
import NewsItem from "containers/authenticated/news-item/NewsItem";
import AnnualStats from "containers/authenticated/annual-stats/AnnualStats";
import NotFound from "containers/not-found/NotFound";
import AuthenticatedRoute from "components/authenticated-route/AuthenticatedRoute";
import UnauthenticatedRoute from "components/unauthenticated-route/UnauthenticatedRoute";

export default ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/signup"
      exact
      component={Signup}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/confirm-account"
      exact
      component={ConfirmAccount}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/forgotten-password"
      exact
      component={ForgottenPassword}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/"
      key={1}
      exact
      component={Home}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/home"
      key={2}
      exact
      component={Home}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/competition-rules/:year/:type"
      key={3}
      exact
      component={CompetitionRules}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/stats/:id"
      key={3}
      exact
      component={Home}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/badges/:id"
      key={1}
      exact
      component={Badges}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/golfer/:id/all-rounds"
      key={1}
      exact
      component={GolferRounds}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/calendar"
      exact
      component={Calendar}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/gallery"
      exact
      component={Gallery}
      props={childProps}
      key={1}
    />
    <AuthenticatedRoute
      path="/gallery/:id"
      exact
      component={Gallery}
      props={childProps}
      key={2}
    />
    <AuthenticatedRoute
      path="/golfers"
      exact
      component={Golfers}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/golfer/:id"
      exact
      component={Golfer}
      props={childProps}
      key={1}
    />
    <AuthenticatedRoute
      path="/courses"
      exact
      component={Courses}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/course/:id"
      exact
      component={Course}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/competitions"
      exact
      component={Competitions}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/competition/summary/:id"
      exact
      component={CompetitionSummary}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/competition/summary/:id/:type"
      exact
      component={CompetitionSummaryByType}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/competition/summary/:id/:type/golfer/:golferId"
      exact
      component={CompetitionSummaryByType}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/competition/summary/:id/:type/round/:round"
      exact
      component={CompetitionSummaryByType}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/competition/summary/:id/:type/golfer/:golferId/round/:round"
      exact
      component={CompetitionSummaryByType}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/competition/:id"
      exact
      component={Competition}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/rounds"
      exact
      component={Rounds}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/round/summary/:id/"
      exact
      component={RoundSummary}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/round/:id"
      exact
      component={Round}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/round/:id/scorecard/:golferId"
      exact
      component={Scorecard}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/round/:id/three-ball-sixes/:groupNumber"
      exact
      component={ThreeBallSixesScorecard}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/golfers-detail"
      exact
      component={GolfersDetail}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/handicaps"
      exact
      component={Handicaps}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/handicaps/:id"
      exact
      component={Handicap}
      props={childProps}
      key={1}
    />
    <AuthenticatedRoute
      path="/honours"
      exact
      component={Honours}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/appearances"
      exact
      component={Appearances}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/news"
      exact
      component={News}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/news/:id"
      exact
      component={NewsItem}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/annual-stats"
      exact
      component={AnnualStats}
      props={childProps}
      key={1}
    />
    <AuthenticatedRoute
      path="/annual-stats/:id"
      exact
      component={AnnualStats}
      props={childProps}
      key={2}
    />
    <AuthenticatedRoute
      path="/my-profile"
      exact
      component={Golfer}
      props={childProps}
      key={2}
    />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
