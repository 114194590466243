import React from "react";
import "./PageHeader.css";

export default props => (
  <div className="PageHeader row">
    <div className={"col-lg-12" + (props.centered ? " text-center" : "")}>
      {props.heading ? (
        <h2 className="section-heading">
          {props.icon ? (
            <span className={props.icon + " nav-header-icon"} />
          ) : null}
          {props.heading}
        </h2>
      ) : (
        ""
      )}
      {props.subSectionHeading ? (
        <h3 className="sub-section-heading">{props.subSectionHeading}</h3>
      ) : (
        ""
      )}
      {props.subHeading ? (
        <h3 className="section-subheading text-muted">{props.subHeading}</h3>
      ) : (
        ""
      )}
    </div>
  </div>
);
