import React from "react";
import { Table } from "react-bootstrap";
import "./AggregatedCompetitionStats.css";

export default props => (
  <div className="AggregatedCompetitionStats">
    <h4>{props.heading ? props.heading : "My Competition Stats"}</h4>
    <Table striped bordered condensed className="basic-stats">
      <tbody>
        <tr>
          <td>
            <span className="fas fa-list-ol" /> Appearances
          </td>
          <td>{props.data.noOfComps}</td>
        </tr>
        <tr>
          <td>
            <span className="fas fa-trophy gold" /> Wins
          </td>
          <td>{props.data.wins}</td>
        </tr>
        <tr>
          <td>
            <span className="fas fa-trophy silver" /> Runners Up
          </td>
          <td>{props.data.runnersUp}</td>
        </tr>
        <tr>
          <td>
            <span className="fas fa-trophy pink" /> Last Places
          </td>
          <td>{props.data.last}</td>
        </tr>
      </tbody>
    </Table>
  </div>
);
