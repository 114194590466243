import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HonoursBoard.css";

export default props => (
  <div className="HonoursBoard">
    <h3>{props.compType}</h3>
    <Table striped bordered condensed>
      <thead>
        <tr>
          <th className="year-col">Year</th>
          <th className="name-col">Winner</th>
          <th className="name-col">Runner Up</th>
        </tr>
      </thead>
      <tbody>
     {props.comps.filter(comp => comp.compType === props.compType).length != 0 ?  
        [{}]
          .concat(props.comps.filter(comp => comp.compType === props.compType))
          .map(
            (item, idx) =>
              idx !== 0 && item.winner && item.winner.id ? (
                <tr key={idx}>
                  <td>
                    <Link to={"/competition/summary/" + item.id}>
                      {item.dateFrom.substring(0, 4)}
                    </Link>
                  </td>
                  <td>
                    {item.winner.givenName + " " + item.winner.familyName}
                  </td>
                  <td>
                    {item.runnerUp && item.runnerUp.id
                      ? item.runnerUp.givenName + " " + item.runnerUp.familyName
                      : null}
                  </td>
                </tr>
              ) : null
          ) : null}
      </tbody>
    </Table>
  </div>
);
