import React, { Component } from "react";
import * as API from "API";
import { withRouter } from "react-router-dom";
import GolferScores from "components/golfer-scores/GolferScores";

class Scorecard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      round: null,
      golfer: null
    };
  }

  componentDidMount = async () => {
    try {
      this.props.setLoading(true);
      const results = await API.getRound(this.props.match.params.id);

      this.props.setLoading(false);

      let golfer = null;

      for (let group of results.groups) {
        for (let groupGolfer of group.golfers) {
          if (groupGolfer.id === this.props.match.params.golferId) {
            golfer = groupGolfer;
            break;
          }
        }
      }
      const round = {
        round: results,
        golfer: golfer
      };
      this.setState(round);
    } catch (e) {
      console.log(e);
      this.props.setError(true);
    }
  };

  getScoreTotals = (golfer, type, field) => {
    const tee =
      golfer.gender === "F" &&
      this.state.round.ladiesTee !== null &&
      this.state.round.ladiesTee !== ""
        ? this.state.round.course.tees[this.state.round.ladiesTee]
        : this.state.round.course.tees[this.state.round.courseTee];
    let front = 0,
      back = 0,
      golferScore = null;
    tee.holes.forEach(hole => {
      golferScore = hole[golfer.id];
      if (golferScore && golferScore[type]) {
        if (hole.nbr <= 9) {
          front = front + (golferScore[type] ? golferScore[type] * 1 : 0);
        } else {
          back = back + (golferScore[type] ? golferScore[type] * 1 : 0);
        }
      }
    });

    const values = {
      total: front + back === 0 ? null : front + back,
      front: front === 0 ? null : front,
      back: back === 0 ? null : back
    };

    return values[field];
  };

  render = () => (
    <div className="Scorecard">
      {this.state.round && this.state.golfer ? (
        <div>
          <GolferScores
            homeClick={e =>
              this.props.history.push("/round/summary/" + this.state.round.id)
            }
            homeName={
              this.state.round.course.courseName +
              " - " +
              this.state.round.roundDate
            }
            name={
              this.state.golfer.givenName + " " + this.state.golfer.familyName
            }
            golfer={this.state.golfer}
            course={this.state.round.course}
            static={true}
            tee={
              this.state.golfer.gender === "F" &&
              this.state.round.ladiesTee !== null &&
              this.state.round.ladiesTee !== ""
                ? this.state.round.course.tees[this.state.round.ladiesTee]
                : this.state.round.course.tees[this.state.round.courseTee]
            }
            totals={this.getScoreTotals}
            showCharts={true}
          />
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(Scorecard);
